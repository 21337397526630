import { useState } from "react";
import { useUser } from "src/contexts/authContext";
import { login } from "src/services/authService";

export const useAuth = () => {
  const [typeAuth, setTypeAuth] = useState("register");
  const [email, setEmail] = useState(""); // /!\ à modifier avant mise en prod
  const [password, setPassword] = useState(""); // /!\ à modifier avant mise en prod
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState([]);

  const {signIn} = useUser();

  const loginVerifier = () => {
    setError([]);
    if (email.length === 0) {
      setError((prev) => [...prev, "Veuillez saisir votre email"]);
    }
    if (password.length === 0) {
      setError((prev) => [...prev, "Veuillez saisir votre mot de passe"]);
    }
    if (error.length !== 0) {
      return;
    }
    signIn(email, password);
  };

  return {
    email,
    setEmail,
    typeAuth,
    setTypeAuth,
    password,
    passwordConfirm,
    setPasswordConfirm,
    setPassword,
    error,
    setError,
    loginVerifier,
  };
};

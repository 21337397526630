import {
  Card, Checkbox, Grid, Button, Stack, List, ListItem, ListItemText, ListItemButton, Divider
} from '@mui/material';

import { useEffect, useState, useRef } from 'react';
import usePermissionsList from 'src/hooks/usePermissionsList';

import useRoleAddPermissions from 'src/hooks/useRoleAddPermissions';
import useRoleRemovePermissions from 'src/hooks/useRoleRemovePermissions';

import PopUpConfirmationRole from './popUpConfirmationRole';
import LoadingSpinner from 'src/components/loadingSpinner';

export default function RoleListPermissions({ idRole, nomRole, roleListPermissions, setRefreshRolesAPI }) {
  //Liste des ville affecter au territoire
  const { permissions: permissionsAvailable, loading, error: errorPermissionsAvailable, setPermissions, setRefreshPermissions } = usePermissionsList();

  //Ajouter les permissions au role
  const { loading: loadingRoleAddPermissions, error: errorRoleAddPermissions, setPermissionsList: setAddPermissionsList, setRoleId: setAddRoleId, affectPermissionsToRole } = useRoleAddPermissions();
  //Supprime les permission du role
  const { loading: loadingRoleRemovePermissions, error: errorRoleRemovePermissions, setPermissionsList: setRemovePermissionsList, setRoleId: setRemoveRoleId, removePermissionsToRole } = useRoleRemovePermissions();

  let permissions_sorted = []
  sort_permission();

  //permet d'avoir un effet de sélection sur les différentes catégories
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [categorySelected, setCategorySelected] = useState(permissions_sorted[selectedIndex]?.category_name || "");

  //Les tableau qui sauvegarde les id selectioné ou déselectioner
  const [listPermissionsDelete, setListPermissionsDelete] = useState([]);
  const [listPermissionsAdd, setListPermissionsAdd] = useState([]);

  //Gestion de la fenêtre de validation
  const [openChangePermissions, setOpenChangePermissions] = useState(false);
  const [popUpValidated, setPopUpValidated] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  //permet de trier les permissions en plusieurs catégories
  function sort_permission() {
    //On créer une nouvelle array avec les 2 listes de permissions
    let list = roleListPermissions.concat(permissionsAvailable);

    list.forEach((item) => {
      //vérification si la permission est déjà attribuée au rôle
      !permissionsAvailable.some(e => e === item) ? item.check = true : item.check = false

      //Vérification que la catégorie existe, sinon on la crée et on rajoute le premier élément
      if (!permissions_sorted.some(e => e.category_name === item.controller)) {
        permissions_sorted.push({ category_name: item.controller, permissions: [item] })
      }
      else {
        //Si la catégorie existe, on rajoute l'élément dans sa catégorie
        let category = permissions_sorted.find(e => e.category_name === item.controller)
        if (!category.permissions.some(e => e.id === item.id)) {

          category.permissions.push(item)
        }
      }

      // Organise les catégories par ordre alphabétique.
      permissions_sorted.sort((a, b) => {
        let category_a = a.category_name.toLowerCase();
        let category_b = b.category_name.toLowerCase();

        if (category_a < category_b) {
          return -1;
        }
        if (category_a > category_b) {
          return 1;
        }
        return 0;
      })
    })
  }

  //Permet de faire defiler la page en bas auto
  const bottomScrollRef = useRef(null);
  useEffect(() => {
    if (!isLoading) {
      bottomScrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [permissionsAvailable, roleListPermissions, isLoading])

  //Remets à zéro la liste des permissions sélectionnées à chaque changement de rôle
  useEffect(() => {
    setRemoveRoleId(idRole)
    setAddRoleId(idRole)
    setRefreshPermissions(true)

    //Remets à zéro les variables pour la sélection de catégories
    setSelectedIndex(0)
    setCategorySelected(permissions_sorted[0]?.category_name || "")

    setListPermissionsAdd([])
    setListPermissionsDelete([])
  }, [idRole])

  useEffect(() => {
    setAddPermissionsList(listPermissionsAdd)
    setRemovePermissionsList(listPermissionsDelete)
  }, [listPermissionsDelete, listPermissionsAdd])

  //Quand la fenêtre pop-up est validé
  useEffect(() => {
    if (popUpValidated) {

      if (listPermissionsAdd.length > 0) {
        affectPermissionsToRole()
        //Remets à zéro la liste des permissions sélectionnées
        setListPermissionsAdd([])
      }

      if (listPermissionsDelete.length > 0) {
        removePermissionsToRole()
        //Remets à zéro la liste des permissions sélectionnées
        setListPermissionsDelete([])
      }
      setRefreshPermissions(true)
      setRefreshRolesAPI(true)
      setPopUpValidated(false)

      //Remets à zéro la liste des permissions sélectionnées
      setListPermissionsAdd([])
      setListPermissionsDelete([])
    }
  }, [popUpValidated])

  useEffect(() => {
    if (loading && loadingRoleAddPermissions && loadingRoleRemovePermissions) {
      setIsLoading(true);
    }
    else {
      setIsLoading(false);
    }
  }, [loading, loadingRoleAddPermissions, loadingRoleRemovePermissions])

  //permet de selectioner une catégorie
  const handleClickSelectCategory = (event, item, index) => {
    setCategorySelected(item.category_name)
    setSelectedIndex(index);
  }

  //Permets d'ajouter la permission dans la bonne liste
  const handleSelectPermissions = (event, permissions) => {
    //Uncheck permissions
    if (permissions.check && !listPermissionsDelete.some(e => e === permissions.id)) {
      setListPermissionsDelete([...listPermissionsDelete, permissions.id])
    }
    else if (permissions.check && listPermissionsDelete.some(e => e === permissions.id)) {
      setListPermissionsDelete(listPermissionsDelete.filter((e) => e !== permissions.id))
    }

    //Check permissions
    if (!permissions.check && !listPermissionsAdd.some(e => e === permissions.id)) {
      setListPermissionsAdd([...listPermissionsAdd, permissions.id])
    }
    else if (!permissions.check && listPermissionsAdd.some(e => e === permissions.id)) {
      setListPermissionsAdd(listPermissionsAdd.filter((e) => e !== permissions.id))
    }

  }

  //Renvoie une valeur true / false, qui se base sur la valeur d'origine et la présence ou non de la permission dans la liste des sélectionnés/désélectionnés. 
  const getTheCheck = (item) => {
    const { check, id } = item;
    const hasDeletePermission = listPermissionsDelete.some((e) => e === id);
    const hasAddPermission = listPermissionsAdd.some((e) => e === id);

    if (check) {
      return hasDeletePermission ? !check : check;
    } else {
      return hasAddPermission ? !check : check;
    }
  };

  //Quand on clique sur le bouton annuler
  const handleCancelSelect = () => {
    setListPermissionsAdd([])
    setListPermissionsDelete([])
  }

  //Quand on clique sur le bouton valider
  const handleValidationSelect = () => {
    setOpenChangePermissions(true)
  }

  if (isLoading) { return <LoadingSpinner />; }
  else {
    return (
      <>
        <h2>Permissions de {nomRole}</h2>
        <Card ref={bottomScrollRef}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs="auto">
              <List>
                {permissions_sorted.map((item, index) => (
                  <ListItemButton key={item.category_name} onClick={(event) => handleClickSelectCategory(event, item, index)} selected={selectedIndex === index}>
                    <ListItemText primary={item.category_name} />
                  </ListItemButton>
                ))
                }
              </List>
            </Grid>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid item xs="auto">
              {categorySelected !== "" ?
                <List>
                  {permissions_sorted.find(e => e.category_name === categorySelected)?.permissions.map((item) => (
                    <ListItemButton key={item.id} onClick={(e) => handleSelectPermissions(e, item)}>
                      <Checkbox checked={getTheCheck(item)} />
                      <ListItemText primary={item.description} />
                    </ListItemButton>
                  ))
                  }
                </List>
                :
                null}
            </Grid>
          </Grid>
          <Divider variant="middle" flexItem />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ margin: '20px' }}
          >
            <Button variant="outlined" color="error" onClick={(e) => handleCancelSelect()} disabled={(listPermissionsAdd.length > 0 || listPermissionsDelete.length > 0) ? false : true}>Annuler</Button>
            <Button variant="contained" onClick={(e) => handleValidationSelect()} disabled={(listPermissionsAdd.length > 0 || listPermissionsDelete.length > 0) ? false : true}>Valider</Button>

          </Stack>
        </Card>
        {openChangePermissions ? <PopUpConfirmationRole
          listCheck={permissionsAvailable.filter((e) => listPermissionsAdd.some((i) => i === e.id))}
          listUncheck={permissionsAvailable.filter((e) => listPermissionsDelete.some((i) => i === e.id))}
          opened={openChangePermissions}
          setOpened={setOpenChangePermissions}
          setValidated={setPopUpValidated}
        /> :
          null}
      </>
    );
  }
}
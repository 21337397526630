import { useState, useEffect } from 'react';
import { get } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useTenantRoles() {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [refreshRoles, setRefreshRoles] = useState(true);

  useEffect(() => {
    if (refreshRoles){
      setLoading(true);
      fetchRoles();
      setRefreshRoles(false);
    }
  }, [refreshRoles]);

  async function getRolesList() {
    try {
      const response = await get(`${baseURL}/admin/roles/tenant`);
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function fetchRoles() {
    try {
      const data = await getRolesList();
      data ? setRoles(data.roles) :  setError('No data received');
      setLoading(false);
    } catch (err) {
      setError(err.message || 'An error occurred');
      setLoading(false);
    }
  }
  return { roles, loading, error, fetchRoles, setRefreshRoles };
}

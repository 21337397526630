import React, { createContext, useContext, useState } from 'react';

const MapContext = createContext();

export const useMapContext = () => {
  return useContext(MapContext);
};

export const MapProvider = ({ children }) => {
  const [mapState, setMapState] = useState({
    center: [-0.49637110062622, 47.46426502103272],
    zoom: 1
  });

  return (
    <MapContext.Provider value={{ mapState, setMapState }}>
      {children}
    </MapContext.Provider>
  );
};

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import ReactPlayer from "react-player";

export const AudioModal = ({ open, onClose, audio }) => (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Transcription complète</DialogTitle>
      <DialogContent>
        <ReactPlayer
          url={audio?.uri}
          controls
          width="100%"
          height="50px"
          style={{
            borderRadius: "8px",
          }}
        />
        <Typography variant="body2" sx={{ marginTop: 2, textAlign:"justify" }}>
          {audio?.retranscription}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
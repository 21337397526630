import { useState, useEffect } from 'react';
import { get } from 'src/api';
const baseURL = process.env.REACT_APP_BASE_URL;

function useTerritories() {
    const [territories, setTerritories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        fetchTerritories();
    }, []);

    useEffect(()=>{
        if(refresh == true){
            fetchTerritories();
            setRefresh(false);
        }
    },[refresh])

    async function getTerritoriesList() {
        try {
            const response = await get(`${baseURL}/admin/territories`);
            return response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            return null;
        }
    }

    async function fetchTerritories() {
        try {
            const data = await getTerritoriesList();
            data ? setTerritories(data.territories) : setError('No data received');
            setLoading(false);
        } catch (err) {
            setError(err.message || 'An error occurred');
            setLoading(false);
        }
    }

    return { territories, loading, error, setRefresh, fetchTerritories };
}

export default useTerritories;

import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Button,
  Checkbox,
  Grid,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Modal,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Radio,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import PopUpConfirmationChangementProblemTypes from "./popUpConfirmationChangementProblemTypes";
import useProblemsType from "src/hooks/useProblemsType";
import useAllProblemType from "src/hooks/useProblemsTypeAll";
import useAffectTerritoryAuthorityProblemType from "src/hooks/useAffectTerritoryAuthorityProblemType";
import useDisassignmentTerritoryAuthorityProblemType from "src/hooks/useDisassignmentTerritoryAuthorityProblemType";
import LoadingSpinner from "src/components/loadingSpinner";
import { DataGrid } from "@mui/x-data-grid";

export default function TerritoryAuthorityProblemType({
  idTerritory,
  idAuthority,
  setAuthorityId,
  authorityName,
  setRefreshAuthority,
}) {
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpValidated, setPopUpValidated] = useState(false);
  const [loadingApiModification, setLoadingApiModification] = useState(false);
  const [territoryId, setTerritoryId] = useState(idTerritory);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const {
    problems_types: assignedProblemTypes,
    loading: assignedProblemTypesLoading,
  } = useProblemsType(territoryId, idAuthority, loadingApiModification);
  const {
    problems_types: availableProblemTypes,
    loading: availableProblemTypesLoading,
  } = useAllProblemType();
  const [isLoading, setIsLoading] = useState(true);
  const bottomScrollRef = useRef(null);
  const [listProblemTypes, setListProblemTypes] = useState([]);
  const [listAddProblemsType, setlistAddProblemsType] = useState([]);
  const [listRemoveProblemsType, setlistRemoveProblemsType] = useState([]);
  const [listProblemTypesOriginal, setListProblemTypesOriginal] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedJson, setSelectedJson] = useState(null);

  const handleOpen = (event) => {
    event.stopPropagation(); // Empêche l'événement de clic de se propager au Paper
    setOpen(true);
  };

  const handleEditClick = (row) => {
    setSelectedRowId(row.id); // Mettre à jour l'état de la ligne sélectionnée
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedJson(null); // Réinitialiser le JSON sélectionné lorsque la boîte de dialogue est fermée
  };

  const rows = [
    {
      id: 1,
      name: "Destination 1",
      connectionType: "API",
      dataTemplate:
        '{"endpoint":"/api/v1/data","method":"GET","headers":{"Authorization":"Bearer token"}}',
      needAuthenticate: true,
      username: "user1",
      needEncryption: true,
      encryptionType: "AES",
    },
    {
      id: 2,
      name: "Destination 2",
      connectionType: "SFTP",
      dataTemplate:
        '{"server":"sftp.example.com","port":22,"directory":"/upload"}',
      needAuthenticate: true,
      username: "user2",
      needEncryption: false,
      encryptionType: "",
    },
    {
      id: 3,
      name: "Destination 3",
      connectionType: "FTP",
      dataTemplate:
        '{"server":"ftp.example.com","port":21,"directory":"/data"}',
      needAuthenticate: false,
      username: "",
      needEncryption: true,
      encryptionType: "SSL",
    },
    {
      id: 4,
      name: "Destination 4",
      connectionType: "API",
      dataTemplate:
        '{"endpoint":"/api/v2/update","method":"POST","headers":{"Content-Type":"application/json"}}',
      needAuthenticate: true,
      username: "user4",
      needEncryption: false,
      encryptionType: "",
    },
    {
      id: 5,
      name: "Destination 5",
      connectionType: "SFTP",
      dataTemplate:
        '{"server":"sftp.example.net","port":22,"directory":"/home/user"}',
      needAuthenticate: true,
      username: "user5",
      needEncryption: true,
      encryptionType: "RSA",
    },
  ];
  const [selectedRow, setSelectedRow] = useState(null); // État pour la ligne sélectionnée

  const handleRadioChange = (params) => {
    setSelectedRow(params.row.id); // Mettre à jour l'état de la ligne sélectionnée
    setSelectedJson(params.row.dataTemplate);
  };
  const columns = [
    {
      field: "radio",
      headerName: "Destination",
      renderCell: (params) => (
        <Radio
          checked={params.id === selectedRow}
          onChange={() => handleRadioChange(params)}
          value={params.id}
          name="radio-button-selection"
          inputProps={{ "aria-label": `${params.id}` }}
        />
      ),
      width: 80,
      sortable: false,
      disableColumnMenu: true,
    },
    { field: "name", headerName: "Nom", flex: 1 },
    { field: "connectionType", headerName: "Type de Connexion", flex: 1 },
  ];

  const {
    error: apiAssignError,
    loading: apiAssignLoading,
    setAuthoritiesId: setApiAssignAuthoritiesId,
    setTerritoryId: setApiAssignTerritoryId,
    setProblemsTypes: setProblemsTypesAssignApi,
    sendTerritoryAuthorityProblemTypes: SendAssignAPI,
  } = useAffectTerritoryAuthorityProblemType();
  const {
    error: apiDisassignError,
    loading: apiDisassignLoading,
    setAuthoritiesId: setApiDisassignAuthoritiesId,
    setTerritoryId: setApiDisassignTerritoryId,
    setProblemsTypes: setProblemsTypesDisassignApi,
    sendTerritoryAuthorityProblemTypes: SendDisassignAPI,
  } = useDisassignmentTerritoryAuthorityProblemType();

  useEffect(() => {
    setlistAddProblemsType([]);
    setlistRemoveProblemsType([]);
  }, [idAuthority, loadingApiModification]);

  useEffect(() => {
    if (apiAssignLoading || apiDisassignLoading) {
      setLoadingApiModification(true);
    } else if (!apiAssignLoading && !apiDisassignLoading) {
      setLoadingApiModification(false);
      if (
        (!listProblemTypesOriginal.some((p) => p.checked === true) &&
          listProblemTypes.some((p) => p.checked === true)) ||
        (!listProblemTypes.some((p) => p.checked === true) &&
          listProblemTypesOriginal.some((p) => p.checked === true))
      ) {
        setRefreshAuthority(true);
      }
    }
  }, [apiAssignLoading, apiDisassignLoading, loadingApiModification]);

  useEffect(() => {
    if (!assignedProblemTypesLoading && !availableProblemTypesLoading) {
      setIsLoading(false);
    }
  }, [assignedProblemTypesLoading, availableProblemTypesLoading]);

  useEffect(() => {
    const newList = availableProblemTypes.map((availablePrbType) => ({
      ...availablePrbType,
      checked: assignedProblemTypes.some(
        (assignedPrbType) => assignedPrbType.id === availablePrbType.id
      ),
    }));
    setListProblemTypes(newList);
    setListProblemTypesOriginal(newList);
  }, [assignedProblemTypes, availableProblemTypes]);


  useEffect(() => {
    setApiAssignAuthoritiesId(idAuthority);
    setApiAssignTerritoryId(territoryId);
    setProblemsTypesAssignApi(listAddProblemsType);

    setApiDisassignAuthoritiesId(idAuthority);
    setApiDisassignTerritoryId(territoryId);
    setProblemsTypesDisassignApi(listRemoveProblemsType);
  }, [listAddProblemsType, listRemoveProblemsType]);

  useEffect(() => {
    if (popUpValidated) {
      if (listAddProblemsType.length > 0) {
        SendAssignAPI();
      }
      if (listRemoveProblemsType.length > 0) {
        SendDisassignAPI();
      }
      setlistAddProblemsType([]);
      setlistRemoveProblemsType([]);
      setPopUpValidated(false);
    }
  }, [popUpValidated]);

  const handleChangeCheck = (event) => {
    const newList = listProblemTypes.map((PrbType) =>
      PrbType.id === event.target.id
        ? {
            ...PrbType,
            checked: !PrbType.checked,
          }
        : PrbType
    );

    if (
      availableProblemTypes.some(
        (PrbTypeAvailable) => PrbTypeAvailable.id !== event.target.id
      ) &&
      event.target.checked &&
      !listAddProblemsType.includes(event.target.id) &&
      !listRemoveProblemsType.includes(event.target.id)
    ) {
      setlistAddProblemsType([...listAddProblemsType, event.target.id]);
    }

    if (
      listAddProblemsType.includes(event.target.id) &&
      event.target.checked === false
    ) {
      setlistAddProblemsType([
        ...listAddProblemsType.filter(
          (problemType) => problemType !== event.target.id
        ),
      ]);
    }

    if (
      assignedProblemTypes.some(
        (PrbTypeAvailable) => PrbTypeAvailable.id === event.target.id
      ) &&
      !event.target.checked &&
      !listAddProblemsType.includes(event.target.id) &&
      !listRemoveProblemsType.includes(event.target.id)
    ) {
      setlistRemoveProblemsType([...listRemoveProblemsType, event.target.id]);
    }

    if (
      listRemoveProblemsType.includes(event.target.id) &&
      event.target.checked === true
    ) {
      setlistRemoveProblemsType([
        ...listRemoveProblemsType.filter(
          (problemType) => problemType !== event.target.id
        ),
      ]);
    }
    setListProblemTypes(newList);
  };

  function handleAffectProblemType() {
    setPopUpOpen(true);
  }

  const transformListProblemTypes = (list) => {
    return list.reduce((acc, curr) => {
      const { category_name, category_icon, ...rest } = curr;
      if (!acc[category_name]) {
        acc[category_name] = {
          category_icon: category_icon,
          problems: [],
        };
      }
      acc[category_name].problems.push(rest);
      return acc;
    }, {});
  };

  const transformedProblemTypes = transformListProblemTypes(listProblemTypes);

  if (isLoading || loadingApiModification) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        {console.log(authorityName)}
        <h2>Compétences de {authorityName}</h2>
        <Card>
          <Grid container spacing={2} style={{ padding: "10px" }}>
            {Object.entries(transformedProblemTypes).map(
              ([category, { category_icon, problems }]) => (
                <Grid item xs={12} key={category}>
                  <Accordion>
                    <AccordionSummary
                      aria-controls={`${category}-content`}
                      id={`${category}-header`}
                    >
                      <img
                        src={`${process.env.REACT_APP_CDN_URL}/problems_types_categories_icons/50%20x%2050/${category_icon}`}
                        alt={category}
                        style={{
                          marginRight: "10px",
                          width: "24px",
                          height: "24px",
                        }}
                      />
                      <Typography variant="h6">{category}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        {problems.map((prbType) => (
                          <Grid item xs={12} sm={6} md={4} key={prbType.id}>
                            <Paper
                              style={{
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between", // Pour espacer le contenu et le bouton
                                cursor: "pointer", // Ajout du curseur pointer pour indiquer que la carte est cliquable
                              }}
                              elevation={3}
                              onClick={() =>
                                handleChangeCheck({
                                  target: {
                                    id: prbType.id,
                                    checked: !prbType.checked,
                                  },
                                })
                              } // Gestionnaire d'événements pour cocher/décocher la case
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  id={prbType.id}
                                  checked={prbType.checked}
                                  onChange={handleChangeCheck}
                                  color="primary"
                                  style={{ pointerEvents: "none" }} // Empêche le clic direct sur la case à cocher de provoquer des comportements non souhaités
                                />
                                <img
                                  src={`${process.env.REACT_APP_CDN_URL}/problems_types_icons/50%20x%2050/${prbType.icon}`}
                                  alt={prbType.name}
                                  style={{
                                    marginRight: "10px",
                                    width: "24px",
                                    height: "24px",
                                  }}
                                />
                                <Typography variant="body1">
                                  {prbType.name}
                                </Typography>
                              </div>
                              <IconButton onClick={handleOpen}>
                                <EditIcon />
                              </IconButton>
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="md"
                  >
                    <DialogTitle id="simple-dialog-title">
                      Attribuer une destination
                    </DialogTitle>
                    <DialogContent>
                      <Box display="flex">
                        <Box flex={1} style={{ height: 400, width: "100%" }}>
                          <DataGrid
                            rows={rows}
                            columns={columns}
                            autoHeight
                            disableRowSelectionOnClick
                            pageSizeOptions={[5]}
                          />
                        </Box>
                        <Box
                          flex={1}
                          ml={2}
                          p={2}
                          border={1}
                          borderColor="grey.300"
                          borderRadius={4}
                        >
                          {selectedJson ? (
                            <pre>
                              {JSON.stringify(
                                JSON.parse(selectedJson),
                                null,
                                2
                              )}
                            </pre>
                          ) : (
                            <p>Sélectionnez un élément pour voir le JSON</p>
                          )}
                        </Box>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              )
            )}
            <Grid
              item
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              ref={bottomScrollRef}
            >
              <Button
                variant="contained"
                style={{
                  marginBottom: "20px",
                  marginTop: "20px",
                  marginRight: "10px",
                }}
                onClick={() => handleAffectProblemType()}
                disabled={
                  listAddProblemsType.length > 0 ||
                  listRemoveProblemsType.length > 0
                    ? false
                    : true
                }
              >
                Enregistrer
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setAuthorityId("")}
              >
                Annuler
              </Button>
            </Grid>
          </Grid>
          <PopUpConfirmationChangementProblemTypes
            listOriginal={listProblemTypesOriginal}
            listAffectation={listAddProblemsType}
            listSuppression={listRemoveProblemsType}
            opened={popUpOpen}
            setOpened={setPopUpOpen}
            setValidated={setPopUpValidated}
          />
        </Card>
      </>
    );
  }
}

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./SyndicModal.css";

const SyndicModal = ({ isOpen, onClose, syndic }) => {
  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === "Escape") {
        onClose();
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          ×
        </button>
        <h2 className="modal-title">{syndic.name}</h2>
        <div className="modal-grid">
          <div className="modal-section">
            <h3>Copropriété</h3>
            <p>
              <strong>Name:</strong> {syndic.condominium_name}
            </p>
            <p>
              <strong>Immatriculation:</strong> {syndic.condominium_immatriculation}
            </p>
            <p>
              <strong>EPCI:</strong> {syndic.condominium_epci}
            </p>
            <p>
              <strong>Address:</strong> {syndic.condominium_address}
            </p>
            <p>
              <strong>Zipcode:</strong> {syndic.condominium_zipcode}
            </p>
            <p>
              <strong>City:</strong> {syndic.condominium_city}
            </p>
          </div>
          <div className="modal-section">
            <h3>Syndic</h3>
            <p>
              <strong>Name:</strong> {syndic.trustee_name}
            </p>
            <p>
              <strong>SIRET:</strong> {syndic.trustee_siret}
            </p>
          </div>
          <div className="modal-section">
            <h3>Autres Informations</h3>
            <p>
              <strong>Immatriculation:</strong> {syndic.immatriculation}
            </p>
            <p>
              <strong>EPCI:</strong> {syndic.epci}
            </p>
            <p>
              <strong>Address:</strong> {syndic.address}
            </p>
            <p>
              <strong>Zipcode:</strong> {syndic.zipcode}
            </p>
            <p>
              <strong>City:</strong> {syndic.city}
            </p>
            <p>
              <strong>Insee Code:</strong> {syndic.inseecode}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

SyndicModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  syndic: PropTypes.object,
};

export default SyndicModal;

import { useState } from 'react';
import { post } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useCreateUser() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function postCreatUser(userEmail, userPassword) {
    try {
      const response = await post(`${baseURL}/signup`, {
        user: {
          email: userEmail,
          password: userPassword
        }
      });
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function postAddUserTenant(userId) {
    try {
      const response = await post(`${baseURL}/admin/tenant/user/${userId}`, {});
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function sendCreateUser(userEmail, userPassword) {
    setLoading(true);
    const dataUser = await postCreatUser(userEmail, userPassword);
    if (!dataUser) {
      setError('No data received');
    }
    else {
      setError(null);
      //Ajout de l'utilisateur au Tenant
      let userId = dataUser.data.id;
      const data = await postAddUserTenant(userId);
      if (!data) {
        setError('No data received');
      }
    }

    setLoading(false);
  }


  return { loading, error, setLoading, sendCreateUser, setError };
}

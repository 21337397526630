import { get } from 'src/api';

const apiService = {
  async getProblemInfo(idproblem) {
    try {
      const response = await get(`/authorities/problem/${idproblem}`);
      console.log(response.data.problem);
      return response.data.problem;
    } catch (error) {
      console.log(error);
    }
  },

};

export default apiService;

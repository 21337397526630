import { useState, useEffect } from 'react';
import { patch } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useUserChangeRole() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [newRoleId, setnewRoleId] = useState("");
  const [userId, setUserId] = useState("");

  async function postChangeRoleUser() {
    try {
      const response = await patch(`${baseURL}/admin/roles/${newRoleId}/user/${userId}`,{});
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function sendChangeRole() {
      setLoading(true);
      const data = await postChangeRoleUser();
      if (!data) {
        setError('No data received');
      }
      setLoading(false);
  }

  return { setUserId, setnewRoleId, loading, error, sendChangeRole };
}
import {
  Box,
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import ReactPlayer from "react-player";


const MAX_TEXT_HEIGHT = 200;

export const renderAudioContent = (
  audios,
  handleOpenDialog
) => {

  return (
    <Box padding={2}>
      <Grid container spacing={2} className="audio-grid">
        {audios.map((audio) => {
          const hasRetranscription = audio.retranscription && audio.retranscription.length > 0;
          const isLongText = hasRetranscription && audio.retranscription.length > 200;
          const shortTranscription = isLongText
            ? audio.retranscription.substring(0, 200) + "..."
            : audio.retranscription;

          return (
            <Grid item xs={12} sm={4} key={audio.id}>
              <Paper
                elevation={3}
                sx={{
                  padding: 2,
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {audio.pourcent < 100 ? (
                  <CircularProgress
                    size={50}
                    thickness={4}
                  />
                ) : (
                  <>
                    <ReactPlayer
                      url={audio.uri}
                      controls
                      height="50px"
                      width="100%"
                      style={{
                        borderRadius: "8px",
                      }}
                    />
                    {hasRetranscription && (
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: 1,
                          maxHeight: isLongText ? MAX_TEXT_HEIGHT : "none",
                          overflow: "hidden",
                          textAlign: "justify",
                        }}
                      >
                        {shortTranscription}
                      </Typography>
                    )}
                    {isLongText && (
                      <Button onClick={() => handleOpenDialog(audio)}>
                        Voir plus
                      </Button>
                    )}
                  </>
                )}
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

import axios from 'axios';

// Pour sauvegarder les informations de connexion
async function saveUserCredentials(username, password) {
  try {
    sessionStorage.setItem(
      'userCredentials',
      JSON.stringify({ username, password }),
    );
  } catch (error) {
    // Gérer les erreurs
    console.error("Failed to save user credentials", error);
  }
}

async function saveToken(tempToken) {
  try {
    sessionStorage.setItem('token', JSON.stringify({ tempToken }));
  } catch (error) {
    // Gérer les erreurs
    console.error("Failed to save token", error);
  }
}

// Pour récupérer les informations de connexion
async function getUserCredentials() {
  try {
    const credentials = sessionStorage.getItem('userCredentials');
    if (credentials) {
      return JSON.parse(credentials);
    }
    return null;
  } catch (error) {
    // Gérer les erreurs
    console.error("Failed to get user credentials", error);
    return null;
  }
}

async function getToken() {
  try {
    let token = sessionStorage.getItem('token');
    token = JSON.parse(token).tempToken;

    if (token) {
      return token;
    }
    return null;
  } catch (error) {
    console.error("Failed to get token", error);
    return null;
  }
}

async function deleteToken() {
  try {
    sessionStorage.removeItem('token');
  } catch (error) {
    // Gérer les erreurs
    console.error("Failed to delete token", error);
  }
}

async function login(username, password) {
  const body = {
    user: {
      email: username,
      password: password,
    },
  };

  saveUserCredentials(username, password);
  console.log(`${process.env.REACT_APP_BASE_URL}/login`, body);

  try {
    const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, body);
    if (res.status === 200) {
      console.log('Login success');
      saveToken(res.headers.authorization);
      return res;
    }
  } catch (error) {
    console.error('Login error', error);
  }
}

async function loginWithCredentials() {
  const { username, password } = await getUserCredentials(); // Récupère les credentials sauvegardés

  const body = {
    user: {
      email: username,
      password: password,
    },
  };

  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`, body);

    saveToken(response.headers.authorization);
    console.log('Token:', response.headers.authorization);
    return response.headers.authorization;
  } catch (error) {
    console.error('Failed to reauthenticate', error);
    throw new Error('Échec de la réauthentification');
  }
}

export {
  saveUserCredentials,
  getUserCredentials,
  login,
  saveToken,
  getToken,
  deleteToken,
  loginWithCredentials,
};

import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Typography, Button } from "@mui/material";
import useProblems from "src/hooks/useProblems";
import MapComponent from "../components/maps/MapComponents";
import DetailsModal from "./DetailsModal";

const MapsPage = () => {
  const { problems, loading, error } = useProblems();


  const markersData =
    problems?.map((problem) => ({
      type: "Feature",
      properties: {
        id: problem.id,
        typeIncident: problem.typeIncident,
      },
      geometry: {
        type: "Point",
        coordinates: [problem.longitude, problem.latitude],
      },
    })) || [];

  

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur : {error.message || error.toString()}</div>;

  return (
    <>
      <Helmet>
        <title>Carte</title>
      </Helmet>
      <Typography variant="h4" gutterBottom>
        Carte des Incidents
      </Typography>

      <MapComponent
        markersData={markersData}
      />
    </>
  );
};

export default MapsPage;

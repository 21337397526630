import { Helmet } from 'react-helmet-async';
import {
  Stack, Container, Typography, Grid
} from '@mui/material';

import useTerritories from 'src/hooks/useTerritories';
import { CitiesList } from 'src/sections/@dashboard/city';
import { Territory } from 'src/sections/@dashboard/TerritoryAuthority';
import useTableManagement from 'src/hooks/useTableManagement';

import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import LoadingSpinner from 'src/components/loadingSpinner';

const TABLE_HEAD = [
  { id: 'name', label: 'Nom', alignRight: false },
  { id: '' },
];

export default function CityPage() {
  const { territories, loading, error, setRefresh } = useTerritories();
  const tableManager = useTableManagement(territories);

  const [territoryId, setTerritoryId] = useState("");

  const emptyRows = tableManager.page > 0 ? Math.max(0, (1 + tableManager.page) * tableManager.rowsPerPage - territories.length) : 0;
  const filteredUsers = tableManager.applySortFilter(territories, tableManager.getComparator(tableManager.order, tableManager.orderBy), tableManager.filterName);

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <Helmet>
        <title> Compétences </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Territoires
          </Typography>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Territory
              tableManager={tableManager}
              territories={territories}
              filteredUsers={filteredUsers}
              emptyRows={emptyRows}
              error={error}
              setTerritoryId={setTerritoryId}
              TABLE_HEAD={TABLE_HEAD}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {territoryId !== "" ? <CitiesList idTerritory={territoryId} nameTerritory={territories.find((territory) => territory.id === territoryId).name} setTerritoryId={setTerritoryId} /> : ""}
          </Grid>
        </Grid>
      </Container>

      <Outlet />
    </>
  );
}

import { useState } from 'react';
import { post } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useAffectCityTerritory() {
  const [territoryId, setTerritoryId] = useState("");
  const [cities, setCities] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function postCityTerritory(cityId) {
    try {
      const response = await post(`${baseURL}/admin/territory/city`, 
      {
        territory: {
          id_city: cityId,
          id_territory: territoryId
        }
      }, 
     );
      if (response.status === 201) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function affectCityToTerritory() {
    for (const city of cities) {
      setLoading(true);
      const data = await postCityTerritory(city);
      if (!data) {
        setError('No data received');
      }
      setLoading(false);
    }
  }

  return { loading, error, setTerritoryId, setCities, affectCityToTerritory };
}

import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { ReactComponent as EditIcon } from "./test.svg";
import typography from "src/theme/typography";

const fakeData = [
  {
    id: 1,
    name: "Destination 1",
    connectionType: "API",
    dataTemplate:
      '{"endpoint":"/api/v1/data","method":"GET","headers":{"Authorization":"Bearer token"}}',
    needAuthenticate: true,
    username: "user1",
    needEncryption: true,
    encryptionType: "AES",
  },
  {
    id: 2,
    name: "Destination 2",
    connectionType: "SFTP",
    dataTemplate:
      '{"server":"sftp.example.com","port":22,"directory":"/upload"}',
    needAuthenticate: true,
    username: "user2",
    needEncryption: false,
    encryptionType: "",
  },
  {
    id: 3,
    name: "Destination 3",
    connectionType: "FTP",
    dataTemplate: '{"server":"ftp.example.com","port":21,"directory":"/data"}',
    needAuthenticate: false,
    username: "",
    needEncryption: true,
    encryptionType: "SSL",
  },
  {
    id: 4,
    name: "Destination 4",
    connectionType: "API",
    dataTemplate:
      '{"endpoint":"/api/v2/update","method":"POST","headers":{"Content-Type":"application/json"}}',
    needAuthenticate: true,
    username: "user4",
    needEncryption: false,
    encryptionType: "",
  },
  {
    id: 5,
    name: "Destination 5",
    connectionType: "SFTP",
    dataTemplate:
      '{"server":"sftp.example.net","port":22,"directory":"/home/user"}',
    needAuthenticate: true,
    username: "user5",
    needEncryption: true,
    encryptionType: "RSA",
  },
];

const MyFormModal = () => {
  const [formData, setFormData] = useState({
    name: "",
    connectionType: "",
    dataTemplate: "",
    needAuthenticate: false,
    username: "",
    password: "",
    needEncryption: false,
    encryptionType: "",
  });

  const [errors, setErrors] = useState({
    dataTemplate: "",
  });

  const [openFormModal, setOpenFormModal] = useState(false);
  const [openJsonModal, setOpenJsonModal] = useState(false);
  const [jsonContent, setJsonContent] = useState("");

  const handleClickOpenFormModal = () => {
    setFormData({
      name: "",
      connectionType: "",
      dataTemplate: "",
      needAuthenticate: false,
      username: "",
      password: "",
      needEncryption: false,
      encryptionType: "",
    });

    setOpenFormModal(true);
  };

  const handleCloseFormModal = () => {
    setOpenFormModal(false);
  };

  const handleClickOpenJsonModal = (json) => {
    setJsonContent(json);
    setOpenJsonModal(true);
  };

  const handleCloseJsonModal = () => {
    setOpenJsonModal(false);
  };

  const handleEditClick = (row) => {
    setFormData({
      name: row.name,
      connectionType: row.connectionType,
      dataTemplate: row.dataTemplate,
      needAuthenticate: row.needAuthenticate,
      username: row.username,
      password: "", // Password is not pre-filled for security reasons
      needEncryption: row.needEncryption,
      encryptionType: row.encryptionType,
    });
    setOpenFormModal(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    if (name === "dataTemplate") {
      validateJSON(value);
    }
  };

  const validateJSON = (json) => {
    try {
      JSON.parse(json);
      setErrors((prevErrors) => ({ ...prevErrors, dataTemplate: "" }));
    } catch (e) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        dataTemplate: "Invalid JSON format",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Additional validation before submission
    if (!errors.dataTemplate && formData.name && formData.connectionType) {
      console.log(formData);
      handleCloseFormModal();
    } else {
      alert("Please correct the errors in the form");
    }
  };

  const columns = [
    { field: "name", headerName: "Nom", width: 150 },
    { field: "connectionType", headerName: "Type de Connexion", width: 150 },
    {
      field: "dataTemplate",
      headerName: "Data Template (JSON)",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => handleClickOpenJsonModal(params.value)}
        >
          JSON
        </Button>
      ),
    },
    {
      field: "needAuthenticate",
      headerName: "Besoin d'Authentification",
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        ),
    },
    { field: "username", headerName: "Nom d'Utilisateur", width: 150 },
    {
      field: "needEncryption",
      headerName: "Besoin de Cryptage",
      width: 150,
      renderCell: (params) =>
        params.value ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        ),
    },
    { field: "encryptionType", headerName: "Type de Cryptage", width: 150 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => handleEditClick(params.row)}
        >
          <EditIcon />
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpenFormModal}
          sx={{ backgroundColor: "blue" }}
        >
          Nouvelle destination
        </Button>
      </Box>
      <Box
        sx={{
          height: 400,
          width: "100%",
          boxShadow: 3,
          borderRadius: 2,
          p: 2,
          backgroundColor: "white",
        }}
      >
        <DataGrid rows={fakeData} columns={columns} pageSize={5} />
      </Box>
      <Dialog
        open={openFormModal}
        onClose={handleCloseFormModal}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle> Nouvelle destination</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <TextField
              label="Nom"
              name="name"
              value={formData.name}
              onChange={handleChange}
              fullWidth
              required
            />
            <FormControl fullWidth required>
              <InputLabel>Type de Connexion</InputLabel>
              <Select
                name="connectionType"
                value={formData.connectionType}
                onChange={handleChange}
              >
                <MenuItem value="API">API</MenuItem>
                <MenuItem value="SFTP">SFTP</MenuItem>
                <MenuItem value="FTP">FTP</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Data Template (JSON)"
              name="dataTemplate"
              value={formData.dataTemplate}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
              required
              error={Boolean(errors.dataTemplate)}
              helperText={errors.dataTemplate}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.needAuthenticate}
                  onChange={handleChange}
                  name="needAuthenticate"
                />
              }
              label="Besoin d'Authentification"
            />
            {formData.needAuthenticate && (
              <>
                <TextField
                  label="Nom d'Utilisateur"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  label="Mot de Passe"
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.needEncryption}
                  onChange={handleChange}
                  name="needEncryption"
                />
              }
              label="Besoin de Cryptage"
            />
            {formData.needEncryption && (
              <TextField
                label="Type de Cryptage"
                name="encryptionType"
                value={formData.encryptionType}
                onChange={handleChange}
                fullWidth
                required
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFormModal} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Soumettre
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openJsonModal}
        onClose={handleCloseJsonModal}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>JSON</DialogTitle>
        <DialogContent>
          <Box
            flex={1}
            ml={2}
            p={2}
            border={1}
            borderColor="grey.300"
            borderRadius={4}
          >
            {jsonContent ? (
              <pre>{JSON.stringify(JSON.parse(jsonContent), null, 2)}</pre>
            ) : (
              <p>Sélectionnez un élément pour voir le JSON</p>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJsonModal} color="primary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MyFormModal;

import { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Snackbar, TextField, InputAdornment, IconButton, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import useCreateRole from 'src/hooks/useCreateRole';

export default function PopUpCreateUser({ opened, setOpened, setRefreshRoles }) {

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const { error, loading, sendCreateRole, setError } = useCreateRole();

  const [nameRole, setNameRole] = useState("");

  const [errorName, setErrorName] = useState(false);

  const [SnackbarMsg, setSnackBarMsg] = useState("Nouveau Rôle créé");

  const handleClose = () => {
    //On réinitialise le message d'erreur
    if(error){
      setError(null);
    }

    //On supprime le texte dans le TextField
    setNameRole("");

    //On réinitialise les messages d'erreur
    setErrorName(false);

    setOpened(false);
  };

  useEffect(() => {
    if (error != null) {
      // setSnackBarMsg("Nom déjà utilisé")
      setSnackBarMsg(error)
    }
    else {
      setSnackBarMsg("Nouvel Rôle créé");
    }
  }, [error])

  const handleSubmit = () => {
    if (!loading) {
      sendCreateRole(nameRole);
    }
    //On ouvre la snackBar
    setSnackBarOpen(true);

    //On supprime le texte dans le TextField
    setNameRole("");
  };

  const handleChangeEmail = (e) => {
    setNameRole(e.target.value)
  }

  useEffect(() => {
    if (!loading && opened && !error) {
      //On met à jour la liste des utilisateurs.
      setRefreshRoles(true);
      handleClose();
    }
  }, [loading])

  return (
    <>
      <Dialog
        fullWidth={false}
        maxWidth='mg'
        keepMounted
        open={opened}
        onClose={handleClose}
      >
        <DialogTitle>Création d'un Rôle</DialogTitle>
        <DialogContent>
          <Typography color="red">{error ? error: null}</Typography>
          <TextField
            autoFocus
            value={nameRole}
            required
            margin="dense"
            id="name"
            name="nom"
            label="Nom du rôle"
            error={errorName}
            helperText={errorName ? "Email incorrect" : null}
            fullWidth
            variant="outlined"
            onChange={(e) => handleChangeEmail(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSubmit} disabled={(errorName || (nameRole === ""))}>Valider</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackBarOpen(false)}
        message={SnackbarMsg}
      />
    </>
  );
}
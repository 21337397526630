import { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, List, ListItem, ListItemText, ListSubheader, ListItemIcon, Divider, Snackbar, Box, Pagination
} from '@mui/material';
import Iconify from 'src/components/iconify';

export default function PopUpConfirmationRole({ listCheck, listUncheck, opened, setOpened, setValidated }) {

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const itemsPerPage = 10;

  const [pageAffectation, setPageAffectation] = useState(1);
  const [noOfPagesAffectation] = useState(
    Math.ceil(listCheck.length / itemsPerPage)
  );
  const handleChangePageAffectation = (event, value) => {
    setPageAffectation(value);
  };

  const [pageSuppression, setPageSuppression] = useState(1);
  const [noOfPagesSuppression] = useState(
    Math.ceil(listUncheck.length / itemsPerPage)
  );
  const handleChangePageSuppression = (event, value) => {
    setPageSuppression(value);
  };

  const handleClose = () => {
    setOpened(false);
    setSnackBarOpen(true);
    setValidated(true);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={opened}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Résumer des changements a appliqués "}</DialogTitle>
        <DialogContent>

          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2} >
            <Grid item>
              {(listUncheck.length > 0 && listCheck.length > 0) ? <Divider orientation="vertical" /> : null}
              {listUncheck.length > 0 ?
                <>
                  <List>
                    <ListSubheader component="div" id="nested-list-subheader">
                      Liste des utilisateurs à supprimer
                    </ListSubheader>
                    {listUncheck.slice((pageSuppression - 1) * itemsPerPage, pageSuppression * itemsPerPage).map((permission) => (

                      <ListItem disablePadding key={permission.id}>
                        <ListItemIcon>
                          <Iconify icon={'material-symbols:indeterminate-check-box'} color={'red'} style={{ marginRight: '10px' }} />
                        </ListItemIcon>
                        <ListItemText primary={permission.name} />
                      </ListItem>
                    ))}
                  </List >
                  {noOfPagesSuppression > 1 ?
                    <>
                      <Divider />
                      <Box component="span">
                        <Pagination
                          count={noOfPagesSuppression}
                          page={pageSuppression}
                          onChange={handleChangePageSuppression}
                          defaultPage={1}
                          color="primary"
                          showFirstButton
                          showLastButton
                        />
                      </Box>
                    </>
                    : null
                  }
                </>
                : null
              }
            </Grid>
            <Grid item>
              {listCheck.length > 0 ?
                <>
                  <List>
                    <ListSubheader component="div" id="nested-list-subheader">
                      Liste des permissions à ajouter
                    </ListSubheader>
                    {listCheck.slice((pageAffectation - 1) * itemsPerPage, pageAffectation * itemsPerPage).map((permission) => (
                      <ListItem disablePadding key={permission.id}>
                        <ListItemIcon>
                          <Iconify icon={'material-symbols:add-box'} color={'blue'} style={{ marginRight: '10px' }} />
                        </ListItemIcon>
                        <ListItemText primary={permission.name} />
                      </ListItem>
                    ))}
                  </List>
                  {noOfPagesAffectation > 1 ?
                    <>
                      <Divider />
                      <Box component="span">
                        <Pagination
                          count={noOfPagesAffectation}
                          page={pageAffectation}
                          onChange={handleChangePageAffectation}
                          defaultPage={1}
                          color="primary"
                          showFirstButton
                          showLastButton
                        />
                      </Box>
                    </>
                    : null
                  }
                </>
                : null
              }
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          Êtes-vous sûr des changements ?
          <Button onClick={handleClose}>Valider</Button>
          <Button onClick={() => { setOpened(false) }} color={'error'}>Annuler</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackBarOpen(false)}
        message="Changement sauvegardé"
      />
    </>
  );
}
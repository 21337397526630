import { useState } from "react";
import { post } from "src/api";

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useRoleAddPermissions() {
  const [permissionsList, setPermissionsList] = useState([]);
  const [roleId, setRoleId] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function postAddPermissions() {
    try {
      const response = await post(
        `${baseURL}/admin/role/add/permissions`,
        {
          role: {
            id: roleId,
            permissions_list_id: permissionsList,
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function affectPermissionsToRole() {
    setLoading(true);
    const data = await postAddPermissions();
    if (!data) {
      setError("No data received");
    }
    setLoading(false);
  }

  return {
    loading,
    error,
    setPermissionsList,
    setRoleId,
    affectPermissionsToRole,
  };
}

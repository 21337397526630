import { useState } from 'react';
import { post } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useAffectTerritoryAuthorityProblemType() {
  const [authorityId, setAuthoritiesId] = useState("");
  const [territoryId, setTerritoryId] = useState("");
  const [problemsTypes, setProblemsTypes] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function postTerritoryAuthorityProblemTypes(idProblemType) {
    try {
      const response = await post(`${baseURL}/admin/territory/${territoryId}/authority/${authorityId}/problem_type/${idProblemType}`, {});
      if (response.status === 201) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function sendTerritoryAuthorityProblemTypes() {
    for (const problemType of problemsTypes) {
      setLoading(true);
      const data = await postTerritoryAuthorityProblemTypes(problemType);
      if (!data) {
        setError('No data received');
      }
      setLoading(false);
    }
  }
  

  return { loading, error, setAuthoritiesId, setTerritoryId, setProblemsTypes, sendTerritoryAuthorityProblemTypes };
}

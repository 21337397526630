import { useState } from 'react';
import { post } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useCreateRole() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function postCreatRole(roleName) {
    try {
      const response = await post(`${baseURL}/admin/role`, {
        role: {
          name: roleName
        }
      },);
      if (response.status === 200) {
        return response.data;
      }
      else {
        return null
      }
    } catch (error) {
      setError(error?.response?.data?.message)
      return null;
    }
  }

  async function sendCreateRole(roleName) {
    setError(null)
    setLoading(true);
    const data = await postCreatRole(roleName);
    setLoading(false);
  }


  return { loading, error, setLoading, sendCreateRole, setError };
}

import { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Snackbar, TextField, InputAdornment, IconButton, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import useCreateUser from 'src/hooks/useCreateUser';

export default function PopUpCreateUser({ opened, setOpened, setRefreshUser }) {

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const { error, loading, sendCreateUser, setError } = useCreateUser();

  const [emailUser, setEmailUser] = useState("");
  const [passwordUser, setPasswordUser] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const [SnackbarMsg, setSnackBarMsg] = useState("Nouvel Utilisateur créé");

  const handleClickShowPassword = () => setShowPassword((showPassword) => !showPassword);


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChangeEmail = (e) => {
    if(error){
      setError(null);
    }
    let regex_email = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm
    let email = e.target.value

    if (regex_email.test(email) || email == "") {
      setErrorEmail(false);
    }
    else {
      setErrorEmail(true);
    }

    setEmailUser(email);
  }

  const handleChangePassword = (e) => {
    let password = e.target.value

    if (password.length >= 6 || password == "") {
      setErrorPassword(false);
    }
    else {
      setErrorPassword(true);
    }

    setPasswordUser(password);
  }

  const handleClose = () => {
    //On réinitialise le message d'erreur
    if(error){
      setError(null);
    }

    //On supprime le texte dans le TextField
    setEmailUser("");
    setPasswordUser("");

    //On réinitialise les messages d'erreur
    setErrorPassword(false);
    setErrorEmail(false);

    setOpened(false);
  };

  useEffect(() => {
    if (error != null) {
      setSnackBarMsg("Email déjà utilisé")
    }
    else {
      setSnackBarMsg("Nouvel Utilisateur créé");
    }
  }, [error])

  const handleSubmit = () => {
    if (!loading) {
      sendCreateUser(emailUser, passwordUser);
    }
    //On ouvre la snackBar
    setSnackBarOpen(true);

    //On supprime le texte dans le TextField
    setEmailUser("");
    setPasswordUser("");
  };

  useEffect(() => {
    if (!loading && opened && !error) {
      //On met à jour la liste des utilisateurs.
      setRefreshUser(true);
      handleClose();
    }
  }, [loading])

  return (
    <>
      <Dialog
        fullWidth={false}
        maxWidth='mg'
        keepMounted
        open={opened}
        onClose={handleClose}
      >
        <DialogTitle>Création d'un utilisateur</DialogTitle>
        <DialogContent>
          <Typography color="red">{error ? "Email déjà utilisé" : null}</Typography>
          <TextField
            autoFocus
            value={emailUser}
            required
            margin="dense"
            type="email"
            id="email"
            name="email"
            label="email de l'utilisateur"
            error={errorEmail}
            helperText={errorEmail ? "Email incorrect" : null}
            fullWidth
            variant="outlined"
            onChange={(e) => handleChangeEmail(e)}
          />
          <TextField
            autoFocus
            value={passwordUser}
            required
            type={!showPassword ? "password" : "text"}
            margin="dense"
            id="password"
            name="password"
            label="mot de passe de l'utilisateur"
            error={errorPassword}
            helperText={errorPassword ? "Mot de passe incorrect (minimum 6 caractères)" : null}
            fullWidth
            variant="outlined"
            onChange={(e) => handleChangePassword(e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    <Iconify icon={showPassword ? "eva:eye-outline" : "eva:eye-off-outline"} />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSubmit} disabled={(errorEmail || errorPassword || (passwordUser == "") || (emailUser == ""))}>Valider</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackBarOpen(false)}
        message={SnackbarMsg}
      />
    </>
  );
}
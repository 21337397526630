import { useState, useEffect } from 'react';
import useAuthorities from 'src/hooks/useAuthorities';

const baseURL = process.env.REACT_APP_BASE_URL;

function useNumberAuthorities() {
    const { authorities, loading, error } = useAuthorities();
    const [numberAuthorities, setNumberAuthorities] = useState(0);

    useEffect(() => {
        getNumberAuthorities();
    }, [loading]);

    function getNumberAuthorities() {
        if(!loading) {
            setNumberAuthorities(authorities.length);
        }
    }

    return { numberAuthorities, loading };
}

export default useNumberAuthorities;

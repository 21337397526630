import { useState } from 'react';
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/iconify';
import { useAuth } from 'src/hooks/useAuth';

export default function LoginForm() {
  const {
    email,
    setEmail,
    password,
    setPassword,
    loginVerifier
  } = useAuth();

  const [showPassword, setShowPassword] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      loginVerifier();
    }
  };

  return (
    <form onKeyDown={handleKeyDown}>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email address"
          value={email}
          onChange={(val) => { setEmail(val.target.value); }}
        />

        <TextField
          name="password"
          label="Password"
          value={password}
          onChange={(val) => { setPassword(val.target.value) }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={loginVerifier}>
          Login
        </LoadingButton>
      </Stack>
    </form>
  );
}

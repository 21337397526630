import { useState } from 'react';
import { remove } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useDisassignmentCityTerritory() {
  const [territoryId, setTerritoryId] = useState("");
  const [cities, setCities] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function postCityTerritory(cityId) {
    try {
      const data = {
        territory: {
          id_city: cityId,
          id_territory: territoryId
        }
      };
  
      console.log(data);
  
      const response = await remove(`${baseURL}/admin/territory/city`, data);
  
      if (response.status === 200) { 
        return response.data;
      }
      return null;
    } catch (error) {
      console.error("Erreur :", error);
      return null;
    }
  }
    
  

  async function disassignCityToTerritory() {
    for (const city of cities) {
      setLoading(true);
      const data = await postCityTerritory(city);
      if (!data) {
        setError('No data received');
      }
      setLoading(false);
    }
  }

  return { loading, error, setTerritoryId, setCities, disassignCityToTerritory };
}

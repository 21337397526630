import { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, Divider, Snackbar
} from '@mui/material';

export default function PopUpChangeUserRole({ newRole, oldRole, opened, setOpened, setValidated }) {

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  console.log("#######################")
  console.log(newRole, '---------', oldRole)

  const handleClose = () => {
    setOpened(false);
    setSnackBarOpen(true);
    setValidated(true);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={opened}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Résumer des changements a appliqués "}</DialogTitle>
        <DialogContent>

          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2} >
            <Grid item>
              {(newRole) ? <Divider orientation="vertical" /> : null}
              {newRole ?
                <>
                  <h3>Nouveau rôle:</h3>
                  {newRole.role_name}
                </>
                : null
              }
            </Grid>
            {(newRole && oldRole) ?
              <Divider orientation="vertical" flexItem />
              : null}
            <Grid item>
              {oldRole ?
                <>
                  <h3>Ancien rôle:</h3>
                  {oldRole.name}
                </>
                : null
              }
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          Êtes-vous sûr des changements ?
          <Button onClick={handleClose}>Valider</Button>
          <Button onClick={() => { setOpened(false) }} color={'error'}>Annuler</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackBarOpen(false)}
        message="Changement sauvegardé"
      />
    </>
  );
}
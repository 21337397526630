import { Helmet } from 'react-helmet-async';
import {
  Card, Table, Stack, Paper, Button, Popover, Checkbox, TableRow,
  MenuItem, TableBody, TableCell, Container, Typography, IconButton,
  TableContainer, TablePagination, Grid,
} from '@mui/material';

import useTenantUsers from 'src/hooks/useTenantUsers';
import Iconify from '../components/iconify';
import useTableManagement from 'src/hooks/useTableManagement';

import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

import PopUpCreateUser from 'src/sections/@dashboard/Users/popUpCreateUser';
import UserList from 'src/sections/@dashboard/Users/usersList'
import LoadingSpinner from 'src/components/loadingSpinner';

const TABLE_HEAD = [
  { id: 'email', label: 'email', alignRight: false }
];

export default function UserPage() {
  const { users: usersList, loading, error, setRefreshUser: setRefreshUserAPI } = useTenantUsers();


  const [openAddUserForm, setOpenAddUserForm] = useState(false);
  const [refreshUser, setRefreshUser] = useState(false);

  const tableManager = useTableManagement(usersList, 'email');

  useEffect(() => {
    //initialize le nombre d'utilisateur pas page au premier nombre de la liste
    tableManager.setRowsPerPage(10);
  }, []);

  useEffect(() => {
    if (refreshUser) {
      setRefreshUserAPI(true);
    }
    if (!loading && refreshUser) {
      setRefreshUser(false);
    }
  }, [refreshUser, loading])

  if (loading) { return <LoadingSpinner /> }
  else {
    return (
      <>
        <Helmet>
          <title> Utilisateur </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Utilisateur
            </Typography>
            <Button variant="contained" onClick={() => setOpenAddUserForm(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
              Ajouter Utilisateur
            </Button>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <UserList
                tableManager={tableManager}
                usersList={usersList}
                error={error}
                TABLE_HEAD={TABLE_HEAD}
                setRefreshUser={setRefreshUser}
              />
            </Grid>
          </Grid>
        </Container >
        <PopUpCreateUser
          opened={openAddUserForm}
          setOpened={setOpenAddUserForm}
          setRefreshUser={setRefreshUser}
        />
        <Outlet />
      </>
    )
  }
}

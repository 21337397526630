export const useMap = () => {
  const createPolygonWithHole = (center, radiusInDegrees, bounds) => {
    const worldBounds = [
      [-180, -90],
      [180, -90],
      [180, 90],
      [-180, 90],
      [-180, -90],
    ];

    return {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [
          worldBounds,
          [
            [bounds.west, bounds.south],
            [bounds.west, bounds.north],
            [bounds.east, bounds.north],
            [bounds.east, bounds.south],
            [bounds.west, bounds.south],
          ],
        ],
      },
    };
  };

  const createCirclePoints = (center, radiusInMeters) => {
    const points = [];
    const degreesBetweenPoints = 10;

    const latDelta = radiusInMeters / 111111;
    const lonDelta =
      radiusInMeters / (111111 * Math.cos(center[1] * (Math.PI / 180)));

    for (let i = 0; i <= 360; i += degreesBetweenPoints) {
      const radian = (i * Math.PI) / 180;
      points.push([
        center[0] + lonDelta * Math.cos(radian),
        center[1] + latDelta * Math.sin(radian),
      ]);
    }

    points.push(points[0]);
    return points;
  };

  const isPointInsideBounds = (point, bounds) => {
    return (
      point[0] >= bounds.west &&
      point[0] <= bounds.east &&
      point[1] >= bounds.south &&
      point[1] <= bounds.north
    );
  };

  function haversineDistance([lon1, lat1], [lon2, lat2]) {
    const R = 6371e3;
    const phi1 = lat1 * (Math.PI / 180);
    const phi2 = lat2 * (Math.PI / 180);
    const deltaPhi = (lat2 - lat1) * (Math.PI / 180);
    const deltaLambda = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
      Math.cos(phi1) *
        Math.cos(phi2) *
        Math.sin(deltaLambda / 2) *
        Math.sin(deltaLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c;
  }

  function moveCameraToPointWithinBounds(center, bounds, radius) {
    if (isPointInsideBounds(center, bounds)) {
      return center;
    }

    const newCenter = [
      Math.max(bounds.west, Math.min(center[0], bounds.east)),
      Math.max(bounds.south, Math.min(center[1], bounds.north)),
    ];

    return newCenter;
  }

  return {
    createPolygonWithHole,
    isPointInsideBounds,
    haversineDistance,
    moveCameraToPointWithinBounds,
  };
};

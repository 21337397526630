import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Grid, Button } from '@mui/material';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { TableListHead, TableListToolbar } from '../CustomTable';
import PopUpConfirmationRole from "./popUpConfirmationRole"
import LoadingSpinner from 'src/components/loadingSpinner';

// import useTenantRoles from 'src/hooks/useTenantRoles';

const rolesPerPage = [10, 25, 50];

export default function RolesList({ tableManager, rolesList, error, TABLE_HEAD, setRoleId, setNomRole,  setPermissionsList }) {
  const emptyRows = tableManager.page > 0 ? Math.max(0, (1 + tableManager.page) * tableManager.rowsPerPage - rolesList.length) : 0;
  const filteredUsers = tableManager.applySortFilterByEmail(rolesList, tableManager.getComparator(tableManager.order, tableManager.orderBy), tableManager.filterName);

  const [listAdd, setListAdd] = useState([]);
  const [listRemove, setListRemove] = useState([]);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [popUpValidated, setPopUpValidated] = useState(false);

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (rolesList.length > 0) {
      {
        tableManager.setSelected(rolesList.filter(user => user.confirmed).map(user => user.id))
      }
    };
  }, [rolesList])

  useEffect(() => {
    if (popUpValidated) {
      setPopUpValidated(false);
    }
  }, [popUpValidated])

  function handleEditButton(role_id, nomRole, permission){
    setPermissionsList(permission)
    setNomRole(nomRole)
    setRoleId(role_id)
  }


  if (isLoading) { return <LoadingSpinner /> }
  else {
    return (
      <Card>
        <TableListToolbar
          numSelected={tableManager.selected.length}
          filterName={tableManager.filterName}
          onFilterName={tableManager.handleFilterByEmail}
          searchName={"Role"}
          showNbSelect={false}
        />
        <Scrollbar>
          <TableContainer>
            <Table>
              <TableListHead
                order={tableManager.order}
                orderBy={tableManager.orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rolesList.length}
                numSelected={tableManager.selected.length}
                onRequestSort={tableManager.handleRequestSort}
                onSelectAllClick={(e) => tableManager.handleSelectAllClick(e, 'role_id')}
              />
              <TableBody>
                {filteredUsers.slice(tableManager.page * tableManager.rowsPerPage, tableManager.page * tableManager.rowsPerPage + tableManager.rowsPerPage).map((row) => {
                  const { role_id, role_name, permission } = row;
                  const selectedUser = tableManager.selected.indexOf(role_id) !== -1;

                  return (
                    <TableRow hover key={role_id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={(selectedUser)} onChange={(event) => tableManager.handleClick(event, role_id)} />
                      </TableCell>
                      <TableCell align="left">{role_name}</TableCell>
                      <TableCell align="right">
                        <IconButton size="large" color="inherit" onClick={() => { handleEditButton(role_id, role_name, permission) }}>
                          <Iconify icon={'eva:edit-fill'} color={'blue'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>

              {error && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper sx={{ textAlign: 'center' }}>
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>
                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{tableManager.filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={rolesPerPage}
          component="div"
          count={rolesList.length}
          rowsPerPage={tableManager.rowsPerPage}
          page={tableManager.page}
          onPageChange={tableManager.handleChangePage}
          onRowsPerPageChange={tableManager.handleChangeRowsPerPage}
        />
        {(listAdd.length > 0 || listRemove.length > 0) ?
          <Grid container justifyContent="center" alignItems="center" padding={2}>
            <Button variant="contained" onClick={() => setOpenConfirmation(true)} startIcon={<Iconify icon="material-symbols:check" />}>
              Valider les changements
            </Button>
          </Grid>
          : null
        }
        {openConfirmation ?
          <PopUpConfirmationRole
            listCheck={listAdd}
            listUncheck={listRemove}
            opened={openConfirmation}
            setOpened={setOpenConfirmation}
            setValidated={setPopUpValidated}
          />
          : null
        }
      </Card>

    )
  }
}
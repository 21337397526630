import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';

import { Grid, Button, List, ListItemButton, ListItemText, Checkbox, Divider, Typography, Stack, IconButton } from '@mui/material';

import Iconify from 'src/components/iconify';

import useTenantRoles from 'src/hooks/useTenantRoles';
import useGetRoleUser from "src/hooks/useGetRoleUser";
import useUserChangeRole from 'src/hooks/useUserChangeRole'

import PopUpChangeUserRole from './popUpChangeUserRole'
import LoadingSpinner from 'src/components/loadingSpinner';


export default function UserDetail({ userSelected }) {

  const [isLoading, setIsLoading] = useState(false);
  const [newRoleSelected, setNewRoleSelected] = useState("");

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [popUpValidated, setPopUpValidated] = useState(false);

  const { roles, loading: loadingRoleList, error: errorRoleList, fetchRoles, setRefreshRoles } = useTenantRoles();
  const { setUserId: setUserIdChangeRole, setnewRoleId, loading: loadingUserChange, error: errorUserChange, sendChangeRole } = useUserChangeRole();

  const { setUserId, loading, userId, error, roleId, fetchRoleUser } = useGetRoleUser();

  let roleList = [];
  roleListChecker();

  useEffect(() => {
    setUserId(userSelected.id)
    setUserIdChangeRole(userSelected.id)
  }, [userSelected]);

  useEffect(() => {
    if (userId !== "") {
      fetchRoleUser()
      fetchRoles()
    }
    setNewRoleSelected("")
  }, [userId])

  useEffect(() => {
    setnewRoleId(newRoleSelected)
  }, [newRoleSelected])

  useEffect(() => {
    if (popUpValidated) {
      setNewRoleSelected("")
      sendChangeRole();
      fetchRoleUser();
      setRefreshRoles(true);
      setPopUpValidated(false)
    }
  }, [popUpValidated])

  useEffect(() => {
    if (!loadingUserChange) {
      fetchRoleUser();
    }
  }, [loadingUserChange])

  useEffect(() => {
    if (loading && loadingRoleList && loadingUserChange) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [loading, loadingRoleList, loadingUserChange])

  //Fonction qui permet d'ajouter une propriété check à chacun des rôles.
  function roleListChecker() {
    roles.forEach((role) => {
      if (role.role_id === roleId.id) {
        role.check = true
      } else {
        role.check = false
      }
      roleList.push(role)
    })
  }

  const handleValidationSelect = () => {
    setOpenConfirmation(true)
  }

  const handleCancelSelect = () => {
    setNewRoleSelected("")
  }

  if (isLoading || roleList.length <= 0) { return <LoadingSpinner /> }
  else {
    return (
      <>
        <h2>Gestion de {userSelected.email}</h2>
        {error ? <Typography color="red">L'utilisateur n'a pas de rôle.</Typography> : null}

        <Card>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs="auto">
              <List>
                {roleList.map((role) => (
                  <ListItemButton key={role.role_id} onClick={(e) => !role.check ? setNewRoleSelected(role.role_id) : null} selected={newRoleSelected === role.role_id}>
                    <Checkbox checked={!!(role.check + (newRoleSelected === role.role_id))} />
                    <ListItemText primary={role.role_name} />
                    {(newRoleSelected === role.role_id) ?
                      <IconButton edge="end">
                        <Iconify icon="material-symbols:autorenew-rounded" color="green" />
                      </IconButton>
                      : null}
                  </ListItemButton>
                ))
                }
              </List>
            </Grid>
          </Grid>
          <Divider variant="middle" flexItem />
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ margin: '20px' }}
          >
            <Button variant="outlined" color="error" onClick={(e) => handleCancelSelect()} disabled={(newRoleSelected) ? false : true}>Annuler</Button>
            <Button variant="contained" onClick={(e) => handleValidationSelect()} disabled={(newRoleSelected) ? false : true}>Valider</Button>
          </Stack>
        </Card>
        {openConfirmation ?
          <PopUpChangeUserRole
            newRole={roleList.find((e) => e.role_id === newRoleSelected)}
            oldRole={roleId}
            opened={openConfirmation}
            setOpened={setOpenConfirmation}
            setValidated={setPopUpValidated}
          />
          : null
        }
      </>
    )
  }
}
import {
  Box,
  Grid,
  Pagination,
  Paper,
  Typography,
  CircularProgress,
} from "@mui/material";
import ReactPlayer from "react-player";

export const renderVideoContent = (
  videos,
) => (
  <Box padding={2}>
    <Grid container spacing={2} className="video-grid">
      {videos.map((video) => (
        <Grid item xs={12} sm={6} md={4} key={video.id}>
          {video.pourcent < 100 ? (
            <Paper
              elevation={3}
              sx={{
                padding: 1,
                position: "relative",
                height: 250,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f0f0f0",
                borderRadius: "8px",
              }}
            >
              {console.log(video)}
              <CircularProgress
                variant="determinate"
                value={video.pourcent}
                size={100}
                thickness={4}
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
              >
                <Typography variant="h6">{`${video.pourcent}%`}</Typography>
              </Box>
            </Paper>
          ) : (
            <Paper
              elevation={3}
              sx={{
                padding: 1,
                position: "relative",
                height: 250,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ReactPlayer
                url={video.uri}
                controls
                width="100%"
                height="100%"
                style={{
                  borderRadius: "8px",
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
              />
            </Paper>
          )}
        </Grid>
      ))}
    </Grid>
  </Box>
);

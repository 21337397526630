import { useState, useEffect } from 'react';
import { get } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

// hooks qui permet de demander à l'API tous les problèmes type disponible
function useAllProblemType() {
    const [problems_types, setProblems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchProblemTypes();
    }, []);

    async function getProblemTypeList() {
        try {
            const response = await get(`${baseURL}/admin/problem_types`);
            if (response.status === 200) {
                console.log(response.data);
                return response.data;
            }
            return null;
        } catch (error) {
            return null;
        }
    }

    async function fetchProblemTypes() {
        try {
            const data = await getProblemTypeList();
            console.log(data);
            data ? setProblems(data.problem_types) : setError('No data received');
            setLoading(false);
        } catch (err) {
            setError(err.message || 'An error occurred');
            setLoading(false);
        }
    }

    return { problems_types, loading, error };
}

export default useAllProblemType;
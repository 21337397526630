import { useState } from 'react';
import { post } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useCreateTerritory() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function postCreatTerritory(territoryNewName) {
    try {
      const response = await post(`${baseURL}/admin/territory`, {
        territory: {
          name: territoryNewName
        }
      },);
      if (response.status === 201) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function sendCreateTerritory(territoryNewName) {
    setLoading(true);
    const data = await postCreatTerritory(territoryNewName);
    if (!data) {
      setError('No data received');
    }
    setLoading(false);
  }


  return { loading, error, setLoading, sendCreateTerritory };
}

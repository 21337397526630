import { useState, useEffect } from 'react';
import { get } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

// hooks qui permet de demander à l'API tous les problèmes type disponible
export default function useGetRoleUser() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [roleId, setRoleId] = useState("");
  const [userId, setUserId] = useState("");


  async function getRoleUser() {
    try {
      const response = await get(`${baseURL}/admin/role/${userId}`);
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function fetchRoleUser() {
    setError(null);
    setRoleId("");
    setLoading(true);
    try {
      const data = await getRoleUser();
      if(data){
        if(Object.hasOwn(data, "role")){
          setRoleId(data.role)
        }
        else if(Object.hasOwn(data, "message")){
          setError("User doesn't have a role")
        }
      }else{
        setError('No data received');
      }
      setLoading(false);
    } catch (err) {
      setError(err.message || 'An error occurred');
      setLoading(false);
    }
  }

  return { setUserId, userId, loading, error, roleId, fetchRoleUser };
}
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Grid, Button } from '@mui/material';

import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { TableListHead, TableListToolbar } from '../CustomTable';
import PopUpConfirmationUser from "./popUpConfirmationUser"

import useTenantUnblockUser from 'src/hooks/useTenantUnblockUser';
import useTenantBlockUser from 'src/hooks/useTenantBlockUser';

import UserDetail from './userDetail';
import LoadingSpinner from 'src/components/loadingSpinner';

const usersPerPage = [10, 25, 50];

export default function UserList({ tableManager, usersList, error, TABLE_HEAD, setRefreshUser }) {
  const emptyRows = tableManager.page > 0 ? Math.max(0, (1 + tableManager.page) * tableManager.rowsPerPage - usersList.length) : 0;
  const filteredUsers = tableManager.applySortFilterByEmail(usersList, tableManager.getComparator(tableManager.order, tableManager.orderBy), tableManager.filterName);

  const [listAdd, setListAdd] = useState([]);
  const [listRemove, setListRemove] = useState([]);

  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [popUpValidated, setPopUpValidated] = useState(false);

  const [userSelected, setUserSelected] = useState("");
  

  //Configuration des hooks pour l'affectation et la désaffectation
  const { error: apiAddError, loading: apiAddLoading, setUsersList: setUserListAddApi, unblockUsers: SendUnblockAPI } = useTenantUnblockUser();
  const { error: apiBlockError, loading: apiBlockLoading, setUsersList: setUserListRemoveApi, blockUsers: SendBlockAPI } = useTenantBlockUser();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ((apiAddLoading || apiBlockLoading) && !isLoading) {
      setIsLoading(true);
    }
    else if (isLoading && !apiAddLoading && !apiBlockLoading) {
      setRefreshUser(true);
    }
  }, [apiBlockLoading, apiAddLoading])

  useEffect(() => {
    if (usersList.length > 0) {
      {
        tableManager.setSelected(usersList.filter(user => user.confirmed).map(user => user.id))
      }
    };
  }, [usersList])

  useEffect(() => {
    let listChangementUser = getDifferences(usersList, tableManager.selected)

    setListAdd(listChangementUser.listUserAdd);
    setListRemove(listChangementUser.listUserRemove);

    setUserListAddApi(listChangementUser.listUserAdd);
    setUserListRemoveApi(listChangementUser.listUserRemove);

  }, [tableManager.selected])

  useEffect(() => {
    if (popUpValidated) {

      if (listAdd.length > 0) {
        SendUnblockAPI();
      }

      if (listRemove.length > 0) {
        SendBlockAPI();
      }

      setPopUpValidated(false);
    }
  }, [popUpValidated])

  // Fonction pour obtenir les différences entre les deux listes
  function getDifferences(listOriginal, listNew) {
    const listUserAdd = [];
    const listUserRemove = [];

    // Parcourir la liste originale
    for (const userOriginal of listOriginal) {
      // Si l'utilisateur est dans la liste listNew et confirmé est faux, on ajouter l'id à la liste toAdd
      if (listNew.includes(userOriginal.id) && !userOriginal.confirmed) {
        listUserAdd.push(userOriginal);
      }
      // Si l'utilisateur n'est pas dans la liste listNew et confirmé est vrai, on ajouter l'id à la liste toRemove
      else if (!listNew.includes(userOriginal.id) && userOriginal.confirmed) {
        listUserRemove.push(userOriginal);
      }
    }

    return { listUserAdd, listUserRemove };
  }

  if (isLoading) { return <LoadingSpinner /> }
  else {
    return (

      <>
        <Card>
          <TableListToolbar
            numSelected={tableManager.selected.length}
            filterName={tableManager.filterName}
            onFilterName={tableManager.handleFilterByEmail}
            searchName={"User"}
            showNbSelect={false}
          />
          <Typography variant="h6">Active ou désactive les utilisateurs</Typography>
          <Scrollbar>
            <TableContainer>
              <Table>
                <TableListHead
                  order={tableManager.order}
                  orderBy={tableManager.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={usersList.length}
                  numSelected={tableManager.selected.length}
                  onRequestSort={tableManager.handleRequestSort}
                  onSelectAllClick={(e) => tableManager.handleSelectAllClick(e, 'id')}
                />
                <TableBody>
                  {filteredUsers.slice(tableManager.page * tableManager.rowsPerPage, tableManager.page * tableManager.rowsPerPage + tableManager.rowsPerPage).map((row) => {
                    const { id, email } = row;
                    const selectedUser = tableManager.selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={(selectedUser)} onChange={(event) => tableManager.handleClick(event, id)} />
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={() => setUserSelected({id: id, email: email})}>
                            <Iconify icon={'eva:edit-fill'} color={'blue'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {error && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper sx={{ textAlign: 'center' }}>
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>
                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{tableManager.filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={usersPerPage}
            component="div"
            count={usersList.length}
            rowsPerPage={tableManager.rowsPerPage}
            page={tableManager.page}
            onPageChange={tableManager.handleChangePage}
            onRowsPerPageChange={tableManager.handleChangeRowsPerPage}
          />

          {(listAdd.length > 0 || listRemove.length > 0) ?
            <Grid container justifyContent="center" alignItems="center" padding={2}>
              <Button variant="contained" onClick={() => setOpenConfirmation(true)} startIcon={<Iconify icon="material-symbols:check" />}>
                Valider les changements
              </Button>
            </Grid>
            : null
          }
          {openConfirmation ?
            <PopUpConfirmationUser
              listCheck={listAdd}
              listUncheck={listRemove}
              opened={openConfirmation}
              setOpened={setOpenConfirmation}
              setValidated={setPopUpValidated}
            />
            : null
          }
        </Card>

        {userSelected !== "" ?
          <UserDetail
            userSelected={userSelected}
          />
          : null
        }
      </>

    )
  }
}
import { useState, useEffect } from 'react';
import { get } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;


export default function usePermissionsList() {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [refreshPermissions, setRefreshPermissions] = useState(false);

  useEffect(() => {
    if (refreshPermissions) {
      setLoading(true);
      fetchPermissions();
      setRefreshPermissions(false)
    }
  }, [refreshPermissions])

  useEffect(() => {
    setLoading(true);
    fetchPermissions();
  }, [])

  async function getPermissionsList() {
    try {
      const response = await get(`${baseURL}/admin/permissions`);
      return response.data;
    } catch (error) {
      if (error.response) {
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

      } else if (error.request) {
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      return null;
    }
  }

  async function fetchPermissions() {
    try {
      const data = await getPermissionsList();
      data ? setPermissions(data.permissions_list) : setError('No data received');
      setLoading(false);
    } catch (err) {
      setError(err.message || 'An error occurred');
      setLoading(false);
    }
  }

  return { permissions, loading, error, setPermissions, setRefreshPermissions };
}

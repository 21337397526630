import React from "react";
import { Modal, Box } from "@mui/material";
import Details from "./Details"; // Assurez-vous que ce chemin est correct

const DetailsModal = ({ incidentId, open, onClose }) => {
  console.log("DetailsModal rendered with incidentId:", incidentId);
  if (!incidentId) return null;

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          height: "100%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1,
        }}
      >
        <Details incidentId={incidentId} onClose={onClose} />
      </Box>
    </Modal>
  );
};

export default DetailsModal;

import React, {createContext, useState, useContext, useEffect} from 'react';
import {deleteToken, getToken, getUserCredentials, login} from '../services/authService';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const useUser = () => useContext(AuthContext);

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const bootstrapAsync = async () => {
      let userToken;
      let myUser;
      setLoading(true);
      try {
        userToken = await getToken();
        myUser = await getUserCredentials();
        setUser(myUser);
      } catch (e) {}
      setToken(userToken);
      setLoading(false);
    };

    bootstrapAsync();
  }, []);

  const authContext = {
    user,
    token,
    loading,
    signIn: async (username, password, navigation) => {
      setLoading(true);
      try {
        const response = await login(username, password);
        setUser({username});
        setToken(response.headers.authorization);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    },
    signOut: () => {
      setToken(null);
      deleteToken();
    },
  };

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};

import { Helmet } from 'react-helmet-async';
import {
  Stack, Button,
  Container, Typography,
  Grid
} from '@mui/material';

import useTerritories from 'src/hooks/useTerritories';
import Iconify from '../components/iconify';
import { Territory, TerritoryAuthority, TerritoryAuthorityProblemType } from '../sections/@dashboard/TerritoryAuthority';
import { PopUpCreateTerritory } from '../sections/@dashboard/Territory';
import useTableManagement from 'src/hooks/useTableManagement';

import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LoadingSpinner from 'src/components/loadingSpinner';
import './TerritoriesPage.css';  // Import the CSS file

const TABLE_HEAD = [
  { id: 'name', label: 'Nom', alignRight: false },
  { id: '' },
];

export default function TerritoriesPage() {
  const { territories, loading, error, setRefresh } = useTerritories();
  const tableManager = useTableManagement(territories);

  const [territoryId, setTerritoryId] = useState("");
  const [authorityId, setAuthorityId] = useState("");
  const [authorityName, setAuthorityName] = useState("");
  const [refreshAuthority, setRefreshAuthority] = useState(false);

  const [openNewTerritoryForm, setOpenNewTerritoryForm] = useState(false);

  useEffect(()=>{
    setRefreshAuthority(true);
    setAuthorityId("");
  },[territoryId])

  const emptyRows = tableManager.page > 0 ? Math.max(0, (1 + tableManager.page) * tableManager.rowsPerPage - territories.length) : 0;
  const filteredUsers = tableManager.applySortFilter(territories, tableManager.getComparator(tableManager.order, tableManager.orderBy), tableManager.filterName);

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <Helmet>
        <title> Compétences </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Compétences
          </Typography>
          <Button variant="contained" onClick={()=> setOpenNewTerritoryForm(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
            Nouvelle Compétence
          </Button>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12} md={territoryId ? 6 : 12} className={`table-container ${territoryId ? 'half-width' : 'full-width'}`}>
            <Territory
              tableManager={tableManager}
              territories={territories}
              filteredUsers={filteredUsers}
              emptyRows={emptyRows}
              error={error}
              setTerritoryId={setTerritoryId}
              TABLE_HEAD={TABLE_HEAD}
            />
          </Grid>
          {territoryId && (
            <Grid item xs={12} md={6}>
              <TerritoryAuthority idTerritory={territoryId}  authority={authorityId}  setAuthorityId={setAuthorityId} setAuthorityName={setAuthorityName} setRefreshAuthority={setRefreshAuthority} refreshAuthority={refreshAuthority}/>
            </Grid>
          )}
          {authorityId && (
            <Grid item xs={12}>
              <TerritoryAuthorityProblemType idTerritory={territoryId} authorityName={authorityName} idAuthority={authorityId} setAuthorityId={setAuthorityId} setRefreshAuthority={setRefreshAuthority}/>
            </Grid>
          )}
        </Grid>
        <PopUpCreateTerritory opened={openNewTerritoryForm} setRefreshTerritory={setRefresh} setOpened={setOpenNewTerritoryForm}/>
      </Container>

      <Outlet />
    </>
  );
}
import { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, List, ListItem, ListItemText, ListSubheader, ListItemIcon, Divider, Snackbar
} from '@mui/material';
import Iconify from 'src/components/iconify';

export default function PopUpConfirmationChangementProblemTypes({ listOriginal, listAffectation, listSuppression, opened, setOpened, setValidated }) {

  //Un trie pour récupere les objet de la liste original avec l'id des autre liste
  //la liste des problemType a affecter
  const listeAffectationPrbType = [...listOriginal.filter((prbType) => (
    listAffectation.includes(prbType.id)
  ))];
  //la liste des problemType a supprimer
  const listeSuppressionPrbType = [...listOriginal.filter((prbType) => (
    listSuppression.includes(prbType.id)
  ))];

  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const handleClose = () => {
    setOpened(false);
    setSnackBarOpen(true);
    setValidated(true);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth='lg'
        open={opened}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Résumer des changements a appliqués"}</DialogTitle>
        <DialogContent>
          <Grid container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={2} >
            <Grid item>
              <List>
                <ListSubheader component="div" id="nested-list-subheader">
                  Liste des Types de Problèmes avant les modification
                </ListSubheader>
                {listOriginal.map((prbtype) => (
                  <ListItem disablePadding key={prbtype.id}>
                    <ListItemIcon>
                      {prbtype.checked ? <Iconify icon={'material-symbols:check-box'} color={'blue'} style={{ marginRight: '10px' }} /> :
                        <Iconify icon={'material-symbols:disabled-by-default'} color={'red'} style={{ marginRight: '10px' }} />
                      }
                    </ListItemIcon>
                    <ListItemText primary={prbtype.name} />
                    {(listeAffectationPrbType.includes(prbtype) || listeSuppressionPrbType.includes(prbtype)) ?
                      <ListItemIcon>
                        <Iconify icon={'material-symbols:release-alert-rounded'} color={'info'} style={{ marginRight: '10px' }} />
                      </ListItemIcon>
                      : null}
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
              {listeAffectationPrbType.length > 0 ?
                <List>
                  <ListSubheader component="div" id="nested-list-subheader">
                    Liste des types de problèmes à affecter
                  </ListSubheader>
                  {listeAffectationPrbType.map((prbtype) => (
                    <ListItem disablePadding key={prbtype.id}>
                      <ListItemIcon>
                        <Iconify icon={'material-symbols:check-box'} color={'blue'} style={{ marginRight: '10px' }} />
                      </ListItemIcon>
                      <ListItemText primary={prbtype.name} />
                    </ListItem>
                  ))}
                </List>
                : null
              }
              {(listeSuppressionPrbType.length > 0 && listeAffectationPrbType.length > 0) ? <Divider /> : null}
              {listeSuppressionPrbType.length > 0 ?
                <List>
                  <ListSubheader component="div" id="nested-list-subheader">
                    Liste des types de problèmes à supprimer
                  </ListSubheader>
                  {listeSuppressionPrbType.map((prbtype) => (
                    <ListItem disablePadding key={prbtype.id}>
                      <ListItemIcon>
                        <Iconify icon={'material-symbols:disabled-by-default'} color={'red'} style={{ marginRight: '10px' }} />
                      </ListItemIcon>
                      <ListItemText primary={prbtype.name} />
                    </ListItem>
                  ))}
                </List>
                : null
              }
            </Grid>
          </Grid>

        </DialogContent>
        <DialogActions>
          Êtes-vous sûr des changements ?
          <Button onClick={handleClose}>Valider</Button>
          <Button onClick={() => {setOpened(false)}} color={'error'}>Annuler</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackBarOpen}
          autoHideDuration={2000}
          onClose={() => setSnackBarOpen(false)}
          message="Changement sauvegardé"
        />
    </>
  );
}
// component
import SvgColor from '../../../components/svg-color';
import Iconify from 'src/components/iconify';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Carte interactive',
    path: '/dashboard/app',
    icon: <Iconify icon="mdi:map-search-outline"/>,
  },
  {
    title: 'utilisateurs',
    path: '/dashboard/user',
    icon: <Iconify icon="mdi:user-group"/>,
  },
  {
    title: 'Rôles',
    path: '/dashboard/roles',
    icon: <Iconify icon="material-symbols:user-attributes"/>,
  },
  {
    title: 'Compétences',
    path: '/dashboard/territories',
    icon: icon('ic_lock'),
  },
  {
    title: 'Territoires',
    path: '/dashboard/cities',
    icon: <Iconify icon="mdi:city" />,
  },
  {
    title: 'Destinations',
    path: '/dashboard/destination',
    icon: <Iconify icon="mdi:code-json" />,
  },
];

export default navConfig;

import {
  Card,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';

import Iconify from 'src/components/iconify';
import useTerritoryAuthorities from 'src/hooks/useTerritoryAuthorities';
import useTerritoryAvailableAuthorities from 'src/hooks/useTerritoryAvailableAuthorities';
import { useEffect, useState } from 'react';
import LoadingSpinner from 'src/components/loadingSpinner';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { TableListToolbar } from '../CustomTable';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: '' },
];

export default function TerritoryAuthority({
  idTerritory,
  setAuthorityId,
  setAuthorityName,
  refreshAuthority,
  setRefreshAuthority,
}) {
  const {
    authorities: assignedAuthorities,
    loading: assignedAuthoritiesLoading,
    setRefreshAuthority: setRefreshAuthorityHooks,
    refreshAuthority: refreshAuthorityHooks,
  } = useTerritoryAuthorities(idTerritory);

  const {
    authorities: availableAuthorities,
    loading: availableAuthoritiesLoading,
    setRefreshAuthorityAvailable: setRefreshAuthorityAvailableHooks,
    refreshAuthorityAvailable: refreshAuthorityAvailableHooks,
  } = useTerritoryAvailableAuthorities(idTerritory);

  const [isLoading, setIsLoading] = useState(true);
  const [listAuthority, setListAuthority] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  let listAuthorityTmp = [];

  useEffect(() => {
    if (refreshAuthority) {
      setIsLoading(true);
      setRefreshAuthorityAvailableHooks(true);
      setRefreshAuthorityHooks(true);
    }
  }, [refreshAuthority]);

  useEffect(() => {
    if (!isLoading) {
      assignedAuthorities.forEach((authority) => {
        authority.checked = true;
        authority.selected = false;
        listAuthorityTmp.push(authority);
      });
      availableAuthorities.forEach((authority) => {
        authority.checked = false;
        authority.selected = false;
        listAuthorityTmp.push(authority);
      });
      setListAuthority(listAuthorityTmp);
      listAuthorityTmp = [];
    }
  }, [isLoading]);

  useEffect(() => {
    setRefreshAuthority(refreshAuthorityHooks);
  }, [refreshAuthorityHooks, refreshAuthorityAvailableHooks]);

  useEffect(() => {
    if (!assignedAuthoritiesLoading && !availableAuthoritiesLoading) {
      setIsLoading(false);
    }
  }, [assignedAuthoritiesLoading, availableAuthoritiesLoading]);

  const handleClick = (event, id, name) => {
    setAuthorityId(id);
    setAuthorityName(name);
    const updatedAuthorities = listAuthority.map((authority) => ({
      ...authority,
      selected: authority.id === id,
    }));
    setListAuthority(updatedAuthorities);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredAuthorities = listAuthority.filter((authority) =>
    authority.name.toLowerCase().includes(filterName.toLowerCase())
  );

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredAuthorities.length - page * rowsPerPage);

  if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <Card sx={{ height: '600px', display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h4" component="h2" sx={{ paddingLeft: 2 }}>
          Services
        </Typography>
        <TableListToolbar
          numSelected={0}
          filterName={filterName}
          onFilterName={handleFilterByName}
          searchName={"Police"}
        />
        <Scrollbar sx={{ flexGrow: 1 }}>
          <TableContainer sx={{ flexGrow: 1, maxHeight: 'calc(100% - 112px)' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  {TABLE_HEAD.map((head) => (
                    <TableCell
                      key={head.id}
                      align={head.alignRight ? 'right' : 'left'}
                    >
                      {head.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAuthorities
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((authority) => (
                    <TableRow
                      key={authority.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      selected={authority.selected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox checked={authority.checked} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {authority.name}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={(event) => handleClick(event, authority.id, authority.name)}
                        >
                          <Iconify icon={'eva:edit-fill'} color={'blue'} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredAuthorities.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Lignes par page"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{ padding: 10}}
        />
      </Card>
    );
  }
}
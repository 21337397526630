import { useState, useEffect } from 'react';
import { get } from 'src/api';
const baseURL = process.env.REACT_APP_BASE_URL;

function useProblemsType(idTerritory, idAuthority, LoadingApiEnded) {
    const [problems_types, setProblems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!LoadingApiEnded) {
            fetchProblemTypes();
        }

    }, [idTerritory, idAuthority, LoadingApiEnded]);//On refait appel à l'API si les variables change.

    async function getProblemTypeList() {
        try {
            const response = await get(`${baseURL}/admin/territory/${idTerritory}/authority/${idAuthority}/problem_type`);

            if (response.status === 200) {
                console.log(response.data);
                return response.data;
            }
            return null;
        } catch (error) {
            return error.response;
        }
    }

    async function fetchProblemTypes() {
        try {
            const data = await getProblemTypeList();
            if (data.problem_types) {
                setProblems(data.problem_types);
            }
            else {
                setProblems([]);
                setError('No data received');
            }

            setLoading(false);
        } catch (err) {
            setError(err.message || 'An error occurred');
            setLoading(false);
        }
    }
    return { problems_types, loading, error };
}

export default useProblemsType;

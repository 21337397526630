import axios from 'axios';

import {getToken, loginWithCredentials} from './services/authService';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Intercepteur de requête pour inclure automatiquement le token d'authentification
axiosInstance.interceptors.request.use(
  async config => {
    const token = await getToken();
    if (token) {
      config.headers['Authorization'] = `${token}`;
      config.timeout = 20000;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

// Intercepteur de réponse pour gérer les erreurs 401 et tenter une reconnexion
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const newToken = await loginWithCredentials();
        if (newToken) {
          originalRequest.headers['Authorization'] = `${newToken}`;
          return axiosInstance(originalRequest);
        }
      } catch (loginError) {
        return Promise.reject(loginError);
      }
    }
    return Promise.reject(error);
  },
);

const get = async (url, params = {}) => {
  try {
    const response = await axiosInstance.get(url, {params});
    return response;
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message);
  }
};

const post = async (url, data = {}) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response;
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message);
  }
};

const put = async (url, data = {}) => {
  try {
    const response = await axiosInstance.put(url, data);
    return response;
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message);
  }
};

const patch = async (url, data = {}) => {
  try {
    const response = await axiosInstance.patch(url, data);
    return response;
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message);
  }
};

const remove = async (url, data) => {
  try {
    const response = await axiosInstance.delete(url, { data });
    return response;
  } catch (error) {
    throw new Error(error.response ? error.response.data : error.message);
  }
};

export {get, post, put, remove, patch};

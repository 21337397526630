/* eslint-disable no-unused-expressions */
import {
  Card, Table, Checkbox, TableRow, Grid,
  TableBody, TableCell, TableContainer, TablePagination, Stack, Typography, Button, Paper, CircularProgress
} from '@mui/material';

import Iconify from 'src/components/iconify';
import useCitiesList from 'src/hooks/useCitiesList';
import useCitiesListAvailable from 'src/hooks/useCitiesListAvailable';
import { useEffect, useState, useRef } from 'react';
import useTableManagement from 'src/hooks/useTableManagement';
import { TableListHead, TableListToolbar } from 'src/sections/@dashboard/CustomTable';
import CityAffectationConfirmationPopUp from './cityAffectationConfirmationPopUp';

import useAffectCityTerritory from 'src/hooks/useAffectCityTerritory';
import useDisassignmentCityTerritory from 'src/hooks/useDisassignmentCityTerritory';

import Scrollbar from 'src/components/scrollbar/Scrollbar';
import LoadingSpinner from 'src/components/loadingSpinner';
// Logic

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'insee_code', label: 'code insee', alignRight: false }
];

export default function CitiesList({ idTerritory, nameTerritory }) {

  //Liste des ville affecter au territoire
  const { cities, loading, error, setTerritoryId: setTerritoryIdCity, setRefreshCity } = useCitiesList();
  //Liste des villes disponibles pour le territoire
  const { cities: citiesAvailable, loading: loadingCityAvailable, error: errorCityAvailable, setTerritoryId: setTerritoryIdCityAvailable, setRefreshCityAvailable } = useCitiesListAvailable();

  //Affectation de villes à un territoire
  const { loading: loadingAffectCity, error: errorAffectCity, setTerritoryId: setAffectCityTerritoryAffectCity, setCities: setCitiesAffect, affectCityToTerritory } = useAffectCityTerritory();
  //Suppression de villes à un territoire
  const { loading: loadingDisassignCity, error: errorDisassignCity, setTerritoryId: setAffectCityTerritoryDisassignCity, setCities: setCitiesDisassign, disassignCityToTerritory } = useDisassignmentCityTerritory();

  const bottomScrollRef = useRef(null);

  //Gestion du tableau pour les villes affectées
  const tableManager = useTableManagement(cities);
  const emptyRows = tableManager.page > 0 ? Math.max(0, (1 + tableManager.page) * tableManager.rowsPerPage - cities.length) : 0;
  const filteredCity = tableManager.applySortFilter(cities, tableManager.getComparator(tableManager.order, tableManager.orderBy), tableManager.filterName);
  //Gestion du tableau pour les villes disponibles
  const tableManagerCityAvailable = useTableManagement(citiesAvailable);
  const emptyRowsCityAvailable = tableManagerCityAvailable.page > 0 ? Math.max(0, (1 + tableManagerCityAvailable.page) * tableManagerCityAvailable.rowsPerPage - citiesAvailable.length) : 0;
  const filteredCityAvailable = tableManagerCityAvailable.applySortFilter(citiesAvailable, tableManagerCityAvailable.getComparator(tableManagerCityAvailable.order, tableManagerCityAvailable.orderBy), tableManagerCityAvailable.filterName);

  //Gestion de la fenêtre de validation
  const [openAffectCity, setOpenAffectCity] = useState(false);
  const [popUpValidated, setPopUpValidated] = useState(false);

  //Permet de gérer le nombre de villes par ligne
  const [listRowPerPage, setListRowPerPage] = useState([5, 10, 25, 50, 100]);

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    if (!isLoading) {
      bottomScrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [cities, citiesAvailable, isLoading])

  //À chaque changement d'idTerritory
  useEffect(() => {
    //On met à jour les hooks avec le nouveau idTerritory
    setTerritoryIdCity(idTerritory);
    setTerritoryIdCityAvailable(idTerritory);
    setAffectCityTerritoryAffectCity(idTerritory);
    setAffectCityTerritoryDisassignCity(idTerritory);
    //On actualise les listes des villes
    setRefreshCity(true);
    setRefreshCityAvailable(true);
    //Mets les sélections des villes à 0
    tableManager.setSelected([]);
    tableManagerCityAvailable.setSelected([]);
    //Remets à la premières pages
    tableManager.setPage(0);
    tableManagerCityAvailable.setPage(0);
    //Remets le nombre de villes par ligne à la valeur par défaut
    tableManager.setRowsPerPage(listRowPerPage[0])
    tableManagerCityAvailable.setRowsPerPage(listRowPerPage[0])
  }, [idTerritory]);

  //À chaque sélection, on met à jour la liste des villes dans les hooks pour l'affection et la suppression de ville
  useEffect(() => {
    let newList = [];
    //Pour la liste des villes à affecter
    //Récupération des ID grâce au nom sélectionné
    tableManagerCityAvailable.selected.map((cityIdSelected) => {
      [
        newList.push(citiesAvailable.find(city => city.id === cityIdSelected).id)
      ]
    });
    setCitiesAffect(newList)//On met à jour la liste dans le hooks

    //On remet à 0 la liste temporaire
    newList = [];

    //Pour la liste des villes à supprimer
    //Récupération des ID grâce au nom sélectionné
    tableManager.selected.map((cityIdSelected) => {
      [
        newList.push(cities.find(city => city.id === cityIdSelected).id)
      ]
    });
    setCitiesDisassign(newList)//On met à jour la liste dans le hooks

  }, [tableManager.selected, tableManagerCityAvailable.selected])

  //Appel de l'API après que le popUp a été validé
  useEffect(() => {
    if (popUpValidated) {
      setIsLoading(true);
      if (tableManager.selected.length > 0) {
        disassignCityToTerritory();
      }
      if (tableManagerCityAvailable.selected.length > 0) {
        affectCityToTerritory();
      }

      //Met les selection a 0
      tableManager.setSelected([]);
      tableManagerCityAvailable.setSelected([]);

      setPopUpValidated(false);
    }
  }, [popUpValidated]);

  //Rafraîchissement des listes des villes après les appels API d'affectation ou de suppressions de ville d'un territoire 
  useEffect(() => {
    if (!loadingAffectCity && !loadingDisassignCity && (!loading || !loadingCityAvailable)) {
      setRefreshCity(true);
      setRefreshCityAvailable(true);
      tableManager.setPage(0);
      tableManagerCityAvailable.setPage(0);
    }
  }, [loadingAffectCity, loadingDisassignCity])

  //Gestion du loading
  useEffect(() => {
    if ((loadingAffectCity || loadingDisassignCity || loading || loadingCityAvailable) && !isLoading) {
      setIsLoading(true);
    }
    else if ((cities.length > 0 || citiesAvailable.length > 0) && (!loading && !loadingCityAvailable && !loadingDisassignCity && !loadingAffectCity)) {
      setIsLoading(false);
    }
  }, [loading, loadingCityAvailable])

  // Render
  if (isLoading) {
    return <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <CircularProgress /><br /><LoadingSpinner />
      </Stack >
    </>;
  }
  else {
    return (
      <>
        <Typography variant="h4" gutterBottom>
          Communes de {nameTerritory}
        </Typography>
        <Card>

          <Grid container justifyContent="space-around" direction="row" alignItems="flex-start" padding="2em">
            <Grid item>
              <Typography variant="h5" gutterBottom>
                Communes affectées
              </Typography>
              <TableListToolbar
                numSelected={tableManager.selected.length}
                filterName={tableManager.filterName}
                onFilterName={tableManager.handleFilterByName}
                searchName={"City"}
              />

              <Scrollbar>
                <TableContainer>
                  <Table>
                    <TableListHead
                      order={tableManager.order}
                      orderBy={tableManager.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={cities.length}
                      numSelected={tableManager.selected.length}
                      onRequestSort={tableManager.handleRequestSort}
                      onSelectAllClick={(e)=>tableManager.handleSelectAllClick(e, 'id')}
                    />
                    <TableBody>
                      {filteredCity.slice(tableManager.page * tableManager.rowsPerPage, tableManager.page * tableManager.rowsPerPage + tableManager.rowsPerPage).map((row) => {
                        const { id, name, insee_code } = row;
                        const selectedCity = tableManager.selected.indexOf(id) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedCity}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedCity} onChange={(event) => tableManager.handleClick(event, id)} />
                            </TableCell>
                            <TableCell align="left">
                              {name}
                            </TableCell>
                            <TableCell align="left">
                              {insee_code}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {error && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper sx={{ textAlign: 'center' }}>
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>
                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{tableManager.filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={listRowPerPage}
                component="div"
                count={cities.length}
                rowsPerPage={tableManager.rowsPerPage}
                page={tableManager.page}
                onPageChange={tableManager.handleChangePage}
                onRowsPerPageChange={tableManager.handleChangeRowsPerPage}
              />
            </Grid>
            <Grid item>
              <Stack
                spacing={2}
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Button variant="outlined" onClick={() => setOpenAffectCity(true)} disabled={!(tableManagerCityAvailable.selected.length > 0 || tableManager.selected.length > 0) ? true : false}>
                  <Iconify icon={'material-symbols:arrow-range-rounded'} color={'blue'} />
                </Button>
              </Stack>
            </Grid>
            <Grid item ref={bottomScrollRef}>
              <Typography variant="h5" gutterBottom>
                Communes disponibles
              </Typography>
              <TableListToolbar
                numSelected={tableManagerCityAvailable.selected.length}
                filterName={tableManagerCityAvailable.filterName}
                onFilterName={tableManagerCityAvailable.handleFilterByName}
                searchName={"City"}
              />

              <Scrollbar>
                <TableContainer>
                  <Table>
                    <TableListHead
                      order={tableManagerCityAvailable.order}
                      orderBy={tableManagerCityAvailable.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={citiesAvailable.length}
                      numSelected={tableManagerCityAvailable.selected.length}
                      onRequestSort={tableManagerCityAvailable.handleRequestSort}
                      onSelectAllClick={(e) => tableManagerCityAvailable.handleSelectAllClick(e, 'id')}
                    />
                    <TableBody>
                      {filteredCityAvailable.slice(tableManagerCityAvailable.page * tableManagerCityAvailable.rowsPerPage, tableManagerCityAvailable.page * tableManagerCityAvailable.rowsPerPage + tableManagerCityAvailable.rowsPerPage).map((row) => {
                        const { id, name, insee_code } = row;
                        const selectedCityAvailable = tableManagerCityAvailable.selected.indexOf(id) !== -1;

                        return (
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedCityAvailable}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedCityAvailable} onChange={(event) => tableManagerCityAvailable.handleClick(event, id)} />
                            </TableCell>
                            <TableCell align="left">
                              {name}
                            </TableCell>
                            <TableCell align="left">
                              {insee_code}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRowsCityAvailable > 0 && (
                        <TableRow style={{ height: 53 * emptyRowsCityAvailable }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {errorCityAvailable && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper sx={{ textAlign: 'center' }}>
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>
                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{tableManagerCityAvailable.filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={listRowPerPage}
                component="div"
                count={citiesAvailable.length}
                rowsPerPage={tableManagerCityAvailable.rowsPerPage}
                page={tableManagerCityAvailable.page}
                onPageChange={tableManagerCityAvailable.handleChangePage}
                onRowsPerPageChange={tableManagerCityAvailable.handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Card>
        {openAffectCity ?
          <CityAffectationConfirmationPopUp
            territoireName={nameTerritory}
            listAffectation={citiesAvailable.filter((city) => tableManagerCityAvailable.selected.includes(city.id))}
            listSuppression={cities.filter((city) => tableManager.selected.includes(city.id))}
            opened={openAffectCity}
            setOpened={setOpenAffectCity}
            setValidated={setPopUpValidated}
          />
          : null
        }
      </>
    );
  }
}
import { useState, useEffect } from 'react';
import useTerritories from 'src/hooks/useTerritories';

const baseURL = process.env.REACT_APP_BASE_URL;

function useNumberTerritories() {
    const { territories, loading, error } = useTerritories();
    const [numberTerritories, setNumberTerritories] = useState(0);

    useEffect(() => {
        getNumberTerritories();
    }, [loading]);

    function getNumberTerritories() {
        if(!loading) {
            setNumberTerritories(territories.length);
        }
    }

    return { numberTerritories, loading };
}

export default useNumberTerritories;

import { useState, useEffect } from "react";
import WebSocketService from "../../services/socketService";
import { useUser } from "src/contexts/authContext";
import { get } from "src/api";

export function useMapsLogic() {
  const [markersData, setMarkersData] = useState([]);
  const [userPosition, setUserPosition] = useState({});
  const [bounds, setBounds] = useState();
  const [center, setCenter] = useState();
  const[socketConnected, setSocketConnected] = useState(false);

  const { token } = useUser();

  useEffect(() => {
    const connectToWebSocket = async () => {
      WebSocketService.connect({ name: "ProblemChannel" }, token, handleMessage);      

      WebSocketService.handleError((error) => {
        console.log("Handle WebSocket error here", error);
      });
    };

    connectToWebSocket();

    return () => {
      WebSocketService.disconnect(); // Cleanup on unmount
    };
  }, [token]);

  const handleMessage = (data) => {
    if(!socketConnected){
      setSocketConnected(true);
      WebSocketService.subscribeToChannel("AuthorityCoordinateChannel", token, handleUserPosition);
    }

    console.log(data);
    try {
      if (data.message?.list) {
        console.log(data.message.list);
        const newMarkers = data.message.list.map((annot) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [annot.longitude, annot.latitude],
          },
          properties: {
            typeIncident: annot.name_problem_type.toLowerCase(),
            id: annot.id,
            marker: annot.icon_problem_type,
            children: annot.children_problems,
          },
        }));
        setMarkersData(newMarkers);
      }

      if (data.message?.new) {
        const newFeature = {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [
              data.message.new.longitude,
              data.message.new.latitude,
            ],
          },
          properties: {
            typeIncident: data.message.new.name_problem_type.toLowerCase(),
            id: data.message.new.id,
            marker: data.message.new.icon_problem_type,
          },
        };
        setMarkersData((currentMarkersData) => [
          ...currentMarkersData,
          newFeature,
        ]);
      }
      if (data.message?.update) {
        setMarkersData((currentMarkersData) =>
          currentMarkersData.map((marker) =>
            marker.properties.id === data.message.update.id
              ? {
                  ...marker,
                  properties: {
                    ...marker.properties,
                    typeIncident: data.message.update.name_problem_type.toLowerCase(),
                    marker: data.message.update.icon_problem_type,
                    children: data.message.update.children_problems,
                  },
                }
              : marker
          )
        );
        
      }
    } catch (error) {
      console.error("Error processing WebSocket message", error);
    }
  };

  const handleUserPosition = (data) => {
    console.log(data);
    if (data && data.message?.coordinates) {
      const { coordinates, id_user } = data.message;
  
      const userFeature = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [coordinates.longitude, coordinates.latitude],
        },
        properties: {
          id: id_user,

          // Vous pouvez ajouter d'autres propriétés si nécessaire
        },
      };
  
      setUserPosition((prevPositions) => ({
        ...prevPositions,
        [id_user]: userFeature,
      }));
    }else if(data && data.message?.message === "USER_DISCONECTED"){
      const { id_user } = data.message;
      setUserPosition((prevPositions) => {
        const { [id_user]: _, ...remainingPositions } = prevPositions; // Exclure l'utilisateur déconnecté
        return remainingPositions;
      });
  
      
      
    }
  };
  

  useEffect(() => {
    const fetchMapParameters = async () => {
      try {
        const res = await get("/authorities/parameters");
        const mapParams = res.data.map_parameters;
        const bounds = {
          north: mapParams.maximum.latitude,
          south: mapParams.minimum.latitude,
          east: mapParams.maximum.longitude,
          west: mapParams.minimum.longitude,
          radius: mapParams.radius,
        };
        setCenter([mapParams.center.longitude, mapParams.center.latitude]);
        setBounds(bounds);
      } catch (error) {
        console.error("Failed to fetch map parameters", error);
      }
    };

    fetchMapParameters();
  }, []);

  return {
    markersData,
    bounds,
    center,
    userPosition,
  };
}

import React from "react";
import {
  Card,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Box,
} from "@mui/material";

import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { TableListHead, TableListToolbar } from "../CustomTable";

function Territory({
  tableManager,
  territories,
  filteredUsers,
  emptyRows,
  error,
  setTerritoryId,
  TABLE_HEAD,
}) {
  return (
    <Card sx={{ height: "600px", display: "flex", flexDirection: "column" }}>
      <Typography variant="h4" component="h2" sx={{ paddingLeft: 2 }}>
        Collectivités
      </Typography>

      <TableListToolbar
        numSelected={tableManager.selected.length}
        filterName={tableManager.filterName}
        onFilterName={tableManager.handleFilterByName}
        searchName={"Angers"}
      />

      <Scrollbar sx={{ flexGrow: 1 }}>
        <TableContainer sx={{ maxHeight: "calc(100% - 112px)" }}>
          <Table stickyHeader>
            <TableListHead
              order={tableManager.order}
              orderBy={tableManager.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={territories.length}
              numSelected={tableManager.selected.length}
              onRequestSort={tableManager.handleRequestSort}
              onSelectAllClick={tableManager.handleSelectAllClick}
            />
            <TableBody>
              {filteredUsers
                .slice(
                  tableManager.page * tableManager.rowsPerPage,
                  tableManager.page * tableManager.rowsPerPage +
                    tableManager.rowsPerPage
                )
                .map((row) => {
                  const { id, name } = row;
                  const selectedUser =
                    tableManager.selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedUser}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedUser}
                          onChange={(event) =>
                            tableManager.handleClick(event, name)
                          }
                        />
                      </TableCell>
                      <TableCell align="left">{name}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={() => {
                            setTerritoryId(id);
                          }}
                        >
                          <Iconify icon={"eva:edit-fill"} color={"blue"} />
                        </IconButton>
                        <IconButton
                          size="large"
                          color="inherit"
                          onClick={tableManager.handleOpenMenu}
                        >
                          <Iconify icon={"eva:trash-2-fill"} color={"red"} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {error && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper sx={{ textAlign: "center" }}>
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>
                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{tableManager.filterName}&quot;</strong>.
                        <br /> Try checking for typos or using complete words.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ mt: "auto" }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={territories.length}
          rowsPerPage={tableManager.rowsPerPage}
          page={tableManager.page}
          onPageChange={tableManager.handleChangePage}
          onRowsPerPageChange={tableManager.handleChangeRowsPerPage}
        />
      </Box>
    </Card>
  );
}

export default Territory;

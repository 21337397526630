class WebSocketService {
  constructor() {
    this.socket = null;
    this.channelCallbacks = {}; // Stockage des callbacks par channel
    this.subscribedChannels = new Set(); // Stockage des canaux déjà abonnés
  }

  // Modifiée pour accepter un channel initial et un callback
  connect(initialChannel = null, token, initialCallback = null) {
    if (this.isConnected()) {
      console.log('WebSocket is already connected');
      if (initialChannel && initialCallback) {
        this.subscribeToChannel(
          initialChannel.name,
          initialChannel.params,
          initialCallback,
        );
      }
      return;
    }

    const wsUrl = `wss://backend.v1.digihelp.fr/cable?token=${encodeURIComponent(
      token,
    )}`;
    this.socket = new WebSocket(wsUrl);

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
      if (initialChannel && initialCallback) {
        // S'abonne directement au channel initial
        this.subscribeToChannel(
          initialChannel.name,
          initialChannel.params,
          initialCallback,
        );
      }
    };

    this.socket.onmessage = e => {
      const message = JSON.parse(e.data);
      const identifier = message.identifier
        ? JSON.parse(message.identifier)
        : {};
      const callback = this.channelCallbacks[identifier.channel];
      if (callback) {
        callback(message);
      }
    };

    this.socket.onerror = error => {
      console.error('WebSocket error:', error.message);
    };

    this.socket.onclose = e => {
      console.log('WebSocket connection closed:', e);
      this.subscribedChannels.clear();
    };
  }

  isConnected() {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }

  subscribeToChannel(channelName, params, callback) {
    console.log(`Subscribing to channel: ${channelName}`);
    const channelIdentifier = JSON.stringify({channel: channelName, ...params});
    // Vérifie si le canal est déjà abonné
    if (this.subscribedChannels.has(channelIdentifier)) {
      console.log(`Already subscribed to channel: ${channelName}`);
      return;
    }

    // Stocke le callback et marque le canal comme abonné
    this.channelCallbacks[channelName] = callback;
    this.subscribedChannels.add(channelIdentifier);

    // Si la connexion est déjà établie, s'abonner directement
    if (this.isConnected()) {
      const message = JSON.stringify({
        command: 'subscribe',
        identifier: channelIdentifier,
      });
      this.socket.send(message);
    }
  }

  unsubscribeFromChannel(channelName, params) {
    const channelIdentifier = JSON.stringify({channel: channelName, ...params});
    const message = JSON.stringify({
      command: 'unsubscribe',
      identifier: channelIdentifier,
    });
    if (this.isConnected()) {
      this.socket.send(message);
    }
    // Retire le callback et marque le canal comme désabonné
    delete this.channelCallbacks[channelName];
    this.subscribedChannels.delete(channelIdentifier);
  }

  sendMessage(message) {
    try {
      if (this.isConnected()) {
        this.socket.send(JSON.stringify(message));
      }
    } catch (error) {
      console.log(error);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.channelCallbacks = {}; // Nettoyer les callbacks
      this.subscribedChannels.clear(); // Nettoyer les canaux abonnés
    }
  }

  handleError(callback) {
    this.socket.onerror = error => {
      console.error('WebSocket encountered error: ', error.message);
      if (callback) {
        callback(error);
      }
    };
  }
}

export default new WebSocketService();

import { useState, useEffect } from 'react';
import { remove } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function useRoleRemovePermissions() {
  const [permissionsList, setPermissionsList] = useState([]);
  const [roleId, setRoleId] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  async function postRemovePermissions() {
    try {
      const response = await remove(`${baseURL}/admin/role/delete/permissions`,
        {
          data: {
            role: {
              id: roleId,
              permissions_list_id: permissionsList
            }
          },
        }
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function removePermissionsToRole() {
    setLoading(true);
    const data = await postRemovePermissions();
    if (!data) {
      setError('No data received');
    }
    setLoading(false);
  }

  return { loading, error, setPermissionsList, setRoleId, removePermissionsToRole };
}

import {
  Box,
  Grid,
  Modal,
  Paper,
  CircularProgress,
} from "@mui/material";

export const renderPhotoContent = (
  images,
  openFullScreen,
  fullScreenImage,
  closeFullScreen,
) => (
  <Box padding={2}>
    <Grid container spacing={2} className="image-grid">
      {images.map((img) => (
        <Grid item xs={12} sm={6} md={4} key={img.id}>
          {img.pourcent < 100 ? (
            <Paper
              elevation={3}
              sx={{
                padding: 1,
                position: "relative",
                height: 250,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#f0f0f0",
                borderRadius: "8px",
              }}
            >
              <CircularProgress size={100} thickness={4} />
            </Paper>
          ) : (
            <Paper
              elevation={3}
              sx={{
                padding: 1,
                position: "relative",
                height: 250,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={img.uri}
                alt="Dynamic"
                onClick={() => openFullScreen(img.uri)}
                sx={{
                  borderRadius: "8px",
                  maxHeight: "100%",
                  maxWidth: "100%",
                  cursor: "pointer",
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  bottom: 8,
                  right: 8,
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  color: "white",
                  padding: "4px 8px",
                  borderRadius: "12px",
                  fontSize: "12px",
                  maxWidth: "90%", // Limite la taille pour les textes plus longs
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {img.name}
              </Box>
            </Paper>
          )}
        </Grid>
      ))}
    </Grid>
    <Modal
      open={!!fullScreenImage}
      onClose={closeFullScreen}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          maxWidth: "90vw",
          maxHeight: "90vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="img"
          src={fullScreenImage}
          alt="Full Screen"
          sx={{
            width: "auto",
            height: "auto",
            maxHeight: "90vh",
            maxWidth: "90vw",
            objectFit: "contain",
            borderRadius: "8px",
          }}
        />
      </Box>
    </Modal>
  </Box>
);

import { useState, useEffect } from 'react';
import { get } from 'src/api';
const baseURL = process.env.REACT_APP_BASE_URL;

function useTerritoryAvailableAuthorities(id) {
    const [authorities, setAuthorities] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refreshAuthorityAvailable, setRefreshAuthorityAvailable] = useState(false);

    useEffect(() => {
        fetchAuthorities();
    }, []);

    useEffect(()=>{
        if(refreshAuthorityAvailable){
            setLoading(true);
            fetchAuthorities();
            setRefreshAuthorityAvailable(false);
        }
    }, [refreshAuthorityAvailable])

    async function getAuthoritiesList() {
        try {
            const response = await get(`${baseURL}/admin/territory/${id}/authority/available`);
            return response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            return null;
        }
    }

    async function fetchAuthorities() {
        try {
            const data = await getAuthoritiesList();
            data ? setAuthorities(data.authorities) : setError('No data received');
            setLoading(false);
        } catch (err) {
            setError(err.message || 'An error occurred');
            setLoading(false);
        }
    }

    return { authorities, loading, error, fetchAuthorities, setRefreshAuthorityAvailable, refreshAuthorityAvailable };
}

export default useTerritoryAvailableAuthorities;

import { useState, useEffect } from 'react';
import { get } from 'src/api';

const baseURL = process.env.REACT_APP_BASE_URL;

function useProblems() {
    const [problems, setProblems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        //fetchProblems();
    }, []);

    async function getProblemList() {
        try {
            const response = await get(`${baseURL}admin/tenants/users`);
            if (response.status === 200) {
                return response.data;
            }
            return null;
        } catch (error) {
            return null;
        }
    }

    async function fetchProblems() {
        try {
            const data = await getProblemList();
            console.log(data);
            data ? setProblems(data.problems) : setError('No data received');
            setLoading(false);
        } catch (err) {
            setError(err.message || 'An error occurred');
            setLoading(false);
        }
    }

    return { problems, loading, error, fetchProblems };
}

export default useProblems;

import { useState, useEffect } from 'react';
import { patch } from 'src/api';
const baseURL = process.env.REACT_APP_BASE_URL;

export default function useTenantUnblockUser() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [usersList, setUsersList] = useState([]);

  const [refreshUser, setRefreshUser] = useState(true);

  useEffect(() => {
    if (refreshUser) {
      unblockUsers();
      setRefreshUser(false);
    }
  }, [refreshUser]);

  async function postUsersUnblock(idUser) {
    try {
      const response = await patch(`${baseURL}/admin/tenant/user/${idUser}/unblock`);
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function unblockUsers() {
    for (const users of usersList) {
      setLoading(true);
      const data = await postUsersUnblock(users.id);
      if (!data) {
        setError('No data received');
      }
    }
    setLoading(false);
  }

  return { users, loading, error, unblockUsers, setRefreshUser, setUsersList};
}

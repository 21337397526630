import { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Snackbar, TextField } from '@mui/material';
import useCreateTerritory from 'src/hooks/useCreateTerritory';

export default function PopUpCreateTerritory({ opened, setOpened, setRefreshTerritory }) {

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const { error, loading, sendCreateTerritory } = useCreateTerritory();
  const [nameTerritory, setNameTerritory] = useState("");


  const handleClose = () => {
    setOpened(false);
  };

  const handleSubmit = () => {
    if (!loading) {
      sendCreateTerritory(nameTerritory);
    }
    //On ouvre la snackBar
    setSnackBarOpen(true);
    //On met à jour la liste des territoires.
    setRefreshTerritory(true);
    handleClose();
    //On supprime le texte dans le TextField
    setNameTerritory("");
  };

  return (
    <>
      <Dialog
        fullWidth={false}
        maxWidth='mg'
        keepMounted
        open={opened}
        onClose={handleClose}
      >
        <DialogTitle>Création d'un territoire</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={nameTerritory}
            required
            margin="dense"
            id="name"
            name="name"
            label="Now du territoire"
            fullWidth
            variant="outlined"
            onChange={(e) => setNameTerritory(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleSubmit}>Valider</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackBarOpen(false)}
        message="Nouveau Territoire créer"
      />
    </>
  );
}
import { useState, useEffect } from "react";
import WebSocketService from "../services/socketService";
import apiService from "src/services/problemsService";
import { useUser } from "src/contexts/authContext";

const useMediaManager = (incidentId) => {
  const [selectedContent, setSelectedContent] = useState("photo");
  const [fullScreenImage, setFullScreenImage] = useState(null);
  const [problemData, setProblemData] = useState(null);
  const [incidentType, setIncidentType] = useState(null);
  const [incidentTypeMap, setIncidentTypeMap] = useState(null);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [audios, setAudios] = useState([]);
  const { token } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      const data = await apiService.getProblemInfo(incidentId);
      setIncidentType(
        `${process.env.REACT_APP_CDN_URL}/problems_types_icons/500%20x%20500/${data.icon_problem_type}`
      );
      setIncidentTypeMap(
        `${process.env.REACT_APP_CDN_URL}/problems_types_markers/125%20x%20172/${data.icon_problem_type}`
      );
      setProblemData(data);
    };
    fetchData();
  }, [incidentId]);

  useEffect(() => {
    const handleMediaElement = (element) => {
      if (!element.url_blured) return;

      const mediaType = getFileType(element.url_blured);

      const name = element.firstname ? element.firstname + " " + element.lastname : problemData.firstname + " " + problemData.lastname;

      switch (mediaType) {
        case "photo":
          fetchMedia(element.url_blured, setImages, name);
          break;
        case "video":
          setVideos((currentMedia) => [
            ...currentMedia,
            {
              id: currentMedia.length + 1,
              uri: element.url_blured,
              name: name,
            },
          ]);
          break;
        case "audio":
          setAudios((currentMedia) => [
            ...currentMedia,
            {
              id: currentMedia.length + 1,
              uri: element.url_audio,
              retranscription: element.retranscription,
            },
          ]);
          break;
        default:
          break;
      }
    };

    const handleMessage = (message) => {
      console.log("WebSocket message received:", message);

      const { code, id, type, pourcent, id_treatment } = message.message || {};
      switch (code) {
        case "MEDIA_TREATED":
        case "ONLY_MERGED_PROBLEM_MEDIA_AVAILABLE":
          if (message.message?.media) {
            message.message.media.forEach(handleMediaElement);
          }

          if (message.message?.merged_problem) {
            message.message.merged_problem.forEach((problem) => {
              problem.media.forEach(handleMediaElement);
            });
          }
          break;

        case "NEW_TREATMENT_IN_PROGRESS":
        case "PROBLEM_MERGED_NEW_TREATMENT_IN_PROGRESS":
          const mediaType = type.split("/")[0];

          switch (mediaType) {
            case "video":
              setSelectedContent("video");
              setVideos((currentMedia) => [
                ...currentMedia,
                { id: id_treatment, pourcent: 0 },
              ]);
              break;
            case "image":
              setSelectedContent("photo");
              setImages((currentMedia) => [
                ...currentMedia,
                { id: id_treatment, pourcent: 0 },
              ]);
              break;
            case "audio":
              setSelectedContent("audio");
              setAudios((currentMedia) => [
                ...currentMedia,
                { id: id_treatment, pourcent: 0 },
              ]);
              break;
            default:
              break;
          }
          break;
        case "UPDATE_POURCENT":
          setVideos((currentMedia) =>
            currentMedia.map((video) =>
              video.id === id_treatment && pourcent != 100
                ? { ...video, pourcent }
                : video
            )
          );
          break;
        case "MEDIA_TREATMENT_IN_PROGRESS":
          type.forEach((mediaType) => {
            const newType = mediaType.type.split("/")[0];
            switch (newType) {
              case "video":
                setVideos((currentMedia) => [
                  ...currentMedia,
                  { id: mediaType.id, pourcent: 0 },
                ]);
                break;
              case "image":
                setImages((currentMedia) => [
                  ...currentMedia,
                  { id: mediaType.id, pourcent: 0 },
                ]);
                break;
              case "audio":
                setAudios((currentMedia) => [
                  ...currentMedia,
                  { id: mediaType.id, pourcent: 0 },
                ]);
                break;
              default:
                break;
            }
          });
          break;
        case "TREATMENT_FINISHED":
        case "PROBLEM_MERGED_TREATMENT_FINISHED":
          
          setVideos((currentMedia) =>
            currentMedia.filter((video) => video.id !== id_treatment)
          );
          setImages((currentMedia) =>
            currentMedia.filter((image) => image.id !== id_treatment)
          );
          setAudios((currentMedia) =>
            currentMedia.filter((audio) => audio.id !== id_treatment)
          );

          if (message.message?.media) {
            message.message.media.forEach(handleMediaElement);
          }
          break;
        default:
          break;
      }
    };
    if (!problemData) {
      return;
    }

    WebSocketService.subscribeToChannel(
      "ProblemAttachmentApplicationNomadeChannel",
      { id_problem: incidentId },
      handleMessage
    );

    return () => {
      WebSocketService.unsubscribeFromChannel(
        "ProblemAttachmentApplicationNomadeChannel",
        { id_problem: incidentId }
      );
    };
  }, [incidentId, problemData]);

  const fetchMedia = async (url, setMedia, name) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      });
      if (response.ok) {
        const blob = await response.blob();
        const contentType = response.headers.get("content-type");
        const mediaUrl = URL.createObjectURL(blob);
        setMedia((currentMedia) => [
          ...currentMedia,
          { id: currentMedia.length + 1, uri: mediaUrl, contentType, name: name },
        ]);
      } else {
        console.error("Failed to fetch media:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching media:", error);
    }
  };

  function getFileType(uri) {
    const extension = uri.split(".").pop().split(/\#|\?/)[0];
    if (["jpg", "jpeg", "png", "gif"].includes(extension.toLowerCase())) {
      return "photo";
    } else if (["mp4", "mov", "avi"].includes(extension.toLowerCase())) {
      return "video";
    } else if (["mp3", "wav", "aac"].includes(extension.toLowerCase())) {
      return "audio";
    } else {
      return "unknown";
    }
  }

  return {
    selectedContent,
    setSelectedContent,
    fullScreenImage,
    setFullScreenImage,
    problemData,
    incidentType,
    incidentTypeMap,
    images,
    videos,
    audios,
    fetchMedia,
    getFileType,
  };
};

export default useMediaManager;

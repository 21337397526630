import { Helmet } from 'react-helmet-async';
import {
  Stack, Button, Container, Typography, Grid,
} from '@mui/material';

import useTenantRoles from 'src/hooks/useTenantRoles';
import Iconify from '../components/iconify';
import useTableManagement from 'src/hooks/useTableManagement';

import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

import RoleList from 'src/sections/@dashboard/Role/rolesList'
import PopUpCreateRole from 'src/sections/@dashboard/Role/popUpCreateRole'

import RoleListPermissions from 'src/sections/@dashboard/Role/roleListPermissions'
import LoadingSpinner from 'src/components/loadingSpinner';

const TABLE_HEAD = [
  { id: 'role_id', label: 'Nom', alignRight: false }
];

export default function RolePage() {
  console.log("RolePage");
  const { roles: rolesList, loading, error, setRefreshRoles: setRefreshRolesAPI } = useTenantRoles();

  const [openAddRolesForm, setOpenAddRolesForm] = useState(false);
  const [refreshRoles, setRefreshRoles] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [nomRole, setNomRole] = useState("");
  const [permissionsList, setPermissionsList] = useState([]);

  const tableManager = useTableManagement(rolesList, 'role_name');

  useEffect(() => {
    if (roleId !== "") {
      setPermissionsList(rolesList.find((e) => e.role_id === roleId).permission)
    }
  }, [roleId, loading])

  useEffect(() => {
    //initialize le nombre d'utilisateur pas page au premier nombre de la liste
    tableManager.setRowsPerPage(10);
  }, []);

  useEffect(() => {
    if (refreshRoles) {
      setRefreshRolesAPI(true);
    }
    if (!loading && refreshRoles) {
      setRefreshRoles(false);
    }
  }, [refreshRoles, loading])

  if (loading) { return <LoadingSpinner />}
  else {
    return (
      <>
        <Helmet>
          <title> Utilisateur </title>
        </Helmet>

        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              Roles
            </Typography>
            <Button variant="contained" onClick={() => setOpenAddRolesForm(true)} startIcon={<Iconify icon="eva:plus-fill" />}>
              Ajouter Role
            </Button>
          </Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <RoleList
                tableManager={tableManager}
                rolesList={rolesList}
                error={error}
                TABLE_HEAD={TABLE_HEAD}
                setRoleId={setRoleId}
                setNomRole={setNomRole}
                setPermissionsList={setPermissionsList}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {roleId !== "" ?
                <RoleListPermissions
                  idRole={roleId}
                  nomRole={nomRole}
                  roleListPermissions={permissionsList}
                  setRefreshRolesAPI={setRefreshRolesAPI}
                />
                : null}
            </Grid>
          </Grid>
        </Container >
        <PopUpCreateRole
          opened={openAddRolesForm}
          setOpened={setOpenAddRolesForm}
          setRefreshRoles={setRefreshRoles}
        />
        <Outlet />
      </>
    )
  }
}

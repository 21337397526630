import React from "react";
import { Box, Typography } from "@mui/material";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { AudioModal } from "src/sections/@dashboard/Details/audioModal";
import { renderPhotoContent, renderAudioContent, renderVideoContent} from "./renderContents";

const RenderContent = ({
  selectedContent,
  hasPhotos,
  hasVideos,
  hasAudios,
  displayedPhotos,
  openFullScreen,
  fullScreenImage,
  closeFullScreen,
  displayedVideos,
  audios,
  handleOpenDialog,
  selectedAudio,
  handleCloseDialog,
}) => {
  const renderContent = () => {
    if (!hasPhotos && !hasVideos && !hasAudios) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant="h6">Aucun média disponible</Typography>
        </Box>
      );
    }
    switch (selectedContent) {
      case "photo":
        return hasPhotos ? (
          renderPhotoContent(
            displayedPhotos,
            openFullScreen,
            fullScreenImage,
            closeFullScreen,
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="h6">Aucune photo disponible</Typography>
          </Box>
        );
      case "video":
        return hasVideos ? (
          renderVideoContent(displayedVideos)
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="h6">Aucune vidéo disponible</Typography>
          </Box>
        );
      case "audio":
        return hasAudios ? (
          <>
            {renderAudioContent(
              audios,
              handleOpenDialog
            )}
            {selectedAudio && (
              <AudioModal
                open={!!selectedAudio}
                onClose={handleCloseDialog}
                audio={selectedAudio}
              />
            )}
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="h6">Aucun audio disponible</Typography>
          </Box>
        );
      default:
        return <div>Select a content type</div>;
    }
  };

  return (
    <TransitionGroup>
      <CSSTransition key={selectedContent} classNames="fade" timeout={300}>
        <Box sx={{ position: "absolute", width: "100%", height: "100%" }}>
          {renderContent()}
        </Box>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default RenderContent;

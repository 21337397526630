import { useState, useEffect } from 'react';
import { useUser } from 'src/contexts/authContext';
import { get } from 'src/api';
const baseURL = process.env.REACT_APP_BASE_URL;

export default function useTenantUsers() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [refreshUser, setRefreshUser] = useState(true);
  const { user } = useUser();

  useEffect(() => {
    if (refreshUser && user){
      setLoading(true);
      fetchUsers();
      setRefreshUser(false);
    }
  }, [refreshUser, user]);

  async function getUsersList() {
    try {
      const response = await get(`${baseURL}/admin/tenants/users`);
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async function fetchUsers() {
    try {
      console.log(user.username);
      const data = await getUsersList();
      data ? setUsers(removeUserConnected(data.users, user.username)) : setError('No data received');
      setLoading(false);
    } catch (err) {
      setError(err.message || 'An error occurred');
      setLoading(false);
    }
  }

  //Permets d'enlever l'utilisateur connecté de la liste
  function removeUserConnected(arr, value) {
    var index = arr.map((e)=> e.email).indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  return { users, loading, error, fetchUsers, setRefreshUser };
}

import React, { useEffect, useState } from "react";
import MyMap from "../components/maps/MyMap";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CameraOff } from "./cameraoff.svg";
import {
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
  Snackbar,
  Alert,
  Badge,
} from "@mui/material";
import {
  CallRounded,
  CameraAltRounded,
  MicOffRounded,
  MicRounded,
  VideocamOffRounded,
  VideocamRounded,
} from "@mui/icons-material";
import useMediaManager from "../hooks/useMediaManager";
import RenderContent from "src/sections/@dashboard/Details/renderContent";

function Details({ incidentId }) {
  const {
    selectedContent,
    setSelectedContent,
    fullScreenImage,
    setFullScreenImage,
    problemData,
    incidentType,
    incidentTypeMap,
    images,
    videos,
    audios,
    mediaProcessing,
    mediaReceived,
  } = useMediaManager(incidentId);

  const navigate = useNavigate();
  console.log(images.length);
  const hasPhotos = images.length > 0;
  const hasVideos = videos.length > 0;
  const hasAudios = audios.length > 0;

  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.code === "Escape") {
        navigate("/dashboard/maps");
      }
    }

    document.addEventListener("keydown", handleEscapeKey);
    return () => document.removeEventListener("keydown", handleEscapeKey);
  }, [navigate]);

  const getIconStyle = (type, hasMedia) => ({
    color: selectedContent === type ? "#1976d2" : "inherit",
    transform: selectedContent === type ? "scale(1.2)" : "scale(1)",
    transition: "transform 0.3s, color 0.3s",
    ...(hasMedia ? {} : { opacity: 0.5 }),
  });

  const openFullScreen = (imageUrl) => {
    setFullScreenImage(imageUrl);
  };

  const closeFullScreen = () => {
    setFullScreenImage(null);
  };

  const handleContentChange = (newContent) => {
    if (selectedContent !== newContent) {
      setSelectedContent(newContent);
    }
  };

  const [selectedAudio, setSelectedAudio] = useState(null);

  const handleOpenDialog = (audio) => {
    setSelectedAudio(audio);
  };

  const handleCloseDialog = () => {
    setSelectedAudio(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        bgcolor: "background.default",
      }}
    >
      <Box component="main" sx={{ display: "flex", flexGrow: 1 }}>
        <Paper
          elevation={3}
          sx={{ flex: 1, padding: 2, margin: 2, textAlign: "center" }}
        >
          <Box
            component="img"
            src={incidentType}
            alt="Incident Type"
            sx={{ width: 100, height: 100, margin: "0 auto 2rem auto" }}
          />
          <Typography variant="h4" gutterBottom>
            {problemData ? problemData.name_problem_type : <CircularProgress />}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {problemData ? problemData.created_at : <CircularProgress />}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {problemData ? (
              problemData.children?.length > 0 ? (
                problemData.children?.length + 1 + " signalements"
              ) : (
                "1 signalement"
              )
            ) : (
              <CircularProgress />
            )}
          </Typography>
          <Typography variant="h6" sx={{ marginTop: 3 }}>
            Les personnes qui ont signalé cet incident :
          </Typography>
          <List>
            {problemData ? (
              <>
                {/* Tri et affichage des enfants */}
                {problemData.children?.length > 0 ? (
                  problemData.children
                    .sort(
                      (a, b) =>
                        new Date(b.created_at_raw) - new Date(a.created_at_raw)
                    ) // Tri par date de création
                    .map((child, index) => (
                      <ListItem
                        key={index}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderBottom: 1,
                          borderColor: "divider",
                          paddingBottom: 1,
                          paddingTop: 1,
                        }}
                      >
                        <ListItemText
                          primary={`${child.firstname} ${child.lastname}`}
                          secondary={`${child.created_at}`}
                          sx={{ textAlign: "left" }}
                        />
                        <IconButton edge="end" aria-label="call">
                          <CallRounded />
                        </IconButton>
                      </ListItem>
                    ))
                ) : (
                  <></>
                )}
                {/* Affichage du parent */}
                <ListItem
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: 1,
                    borderColor: "divider",
                    paddingBottom: 1,
                    paddingTop: 1,
                  }}
                >
                  <ListItemText
                    primary={`${problemData.firstname} ${problemData.lastname}`}
                    secondary={`${problemData.created_at}`}
                    sx={{ textAlign: "left" }}
                  />
                  <IconButton edge="end" aria-label="call">
                    <CallRounded />
                  </IconButton>
                </ListItem>
              </>
            ) : (
              <CircularProgress />
            )}
          </List>
        </Paper>
        <Paper
          elevation={3}
          sx={{ flex: 4, display: "flex", flexDirection: "column", margin: 2 }}
        >
          <Box>
            <MyMap marker={incidentTypeMap} data={problemData} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              padding: 1,
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Badge badgeContent={images.length} color="error">
              <IconButton onClick={() => handleContentChange("photo")}>
                {hasPhotos ? (
                  <CameraAltRounded sx={getIconStyle("photo", hasPhotos)} />
                ) : (
                  <CameraOff
                    color="#B5BBC2"
                    sx={getIconStyle("photo", hasPhotos)}
                  />
                )}
              </IconButton>
            </Badge>
            <Badge badgeContent={videos.length} color="error">
              <IconButton onClick={() => handleContentChange("video")}>
                {hasVideos ? (
                  <VideocamRounded sx={getIconStyle("video", hasVideos)} />
                ) : (
                  <VideocamOffRounded sx={getIconStyle("video", hasVideos)} />
                )}
              </IconButton>
            </Badge>
            <Badge badgeContent={audios.length} color="error">
              <IconButton onClick={() => handleContentChange("audio")}>
                {hasAudios ? (
                  <MicRounded sx={getIconStyle("audio", hasAudios)} />
                ) : (
                  <MicOffRounded sx={getIconStyle("audio", hasAudios)} />
                )}
              </IconButton>
            </Badge>
          </Box>
          <Box sx={{ flex: 1, overflow: "auto", position: "relative" }}>
            <RenderContent
              selectedContent={selectedContent}
              hasPhotos={hasPhotos}
              hasVideos={hasVideos}
              hasAudios={hasAudios}
              displayedPhotos={images}
              openFullScreen={openFullScreen}
              fullScreenImage={fullScreenImage}
              closeFullScreen={closeFullScreen}
              displayedVideos={videos}
              audios={audios}
              handleOpenDialog={handleOpenDialog}
              selectedAudio={selectedAudio}
              handleCloseDialog={handleCloseDialog}
            />
          </Box>
        </Paper>
      </Box>
      <Snackbar
        open={mediaProcessing}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert
          severity="warning"
          sx={{
            fontSize: "1rem",
            bgcolor: "orange",
            color: "white",
            borderRadius: "8px",
          }}
        >
          Media en cours de traitement
        </Alert>
      </Snackbar>
      <Snackbar
        open={mediaReceived}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={3000}
      >
        <Alert
          severity="success"
          sx={{
            fontSize: "1rem",
            bgcolor: "green",
            color: "white",
            borderRadius: "8px",
          }}
        >
          Media reçu
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Details;

import React, { useRef, useEffect } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

const MyMap = ({ marker, data }) => { // Destructuring to get markerImage from props
  const mapContainerRef = useRef(null);
  const bounds = [
    [-0.5988288, 47.44862],
    [-0.5119737, 47.49455]
  ];

  useEffect(() => {
    if (!marker || !data) return;
    const map = new maplibregl.Map({
      container: mapContainerRef.current,
      style: 'https://api.maptiler.com/maps/basic-v2/style.json?key=XLIbvWzZzywSuTAXpTMU',
      center: [data.longitude, data.latitude],
      zoom: 18,
      maxBounds: bounds,
    });


    map.on('load', () => {
      // Create a new HTML element for the custom marker
      const el = document.createElement('div');
      el.className = 'custom-marker';
      el.style.backgroundImage = `url(${marker})`;
      el.style.width = '27px';
      el.style.height = '35px';
      el.style.backgroundSize = 'cover';
      new maplibregl.Marker({ element: el })
        .setLngLat([data.longitude, data.latitude])
        .addTo(map);
    });

    console.log(data);
    data.children?.forEach((child) => {
      const el = document.createElement('div');
      el.className = 'custom-marker';
      el.style.backgroundImage = `url(${marker})`;
      el.style.width = '27px';
      el.style.height = '35px';
      el.style.backgroundSize = 'cover';
      new maplibregl.Marker({ element: el })
        .setLngLat([child.longitude, child.latitude])
        .addTo(map);
    });

    map.addControl(new maplibregl.NavigationControl(), 'top-right');

    return () => map.remove();
  }, [marker, data]); // Include markerImage in the dependency array

  return <div ref={mapContainerRef} className="map-container" style={{ height: '250px', width: '100%' }} />;
};

export default MyMap;
